import type { Locales } from '$lib/routes'
import {
	HouseHeatType,
	IsoLevel,
	type WoltAdvisorFromQueryRequest,
	type WoltAdvisorFveRequest
} from '$lib/types/woltadvisor'
import { PUBLIC_ENVIRONMENT, PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
import { HEAT_PUMP } from '$params/heat_pump'
import { PHOTOVOLTAICS } from '$params/photovoltaics'
interface LocalesDefaults {
	WOLTAIR_CZ: MarketplaceDefaults
	WOLTAIR_DE: MarketplaceDefaults
	WOLTAIR_PL: MarketplaceDefaults
	WOLTAIR_IT: MarketplaceDefaults
}

export const getAttrFromConst = (
	array: [],
	searchAttr: string,
	value: string,
	resultAttr: string
) => {
	const result = array.find((object) => object[searchAttr] === value) || null
	return (result && result[resultAttr]) || value
}
export const CITIES_WA = [
	'Benesov',
	'Beroun (Kraluv Dvur)',
	'Blansko (Dolni Lhota)',
	'Brno',
	'Bruntal',
	'Breclav (Lednice)',
	'Ceska Lipa',
	'Ceske Budejovice',
	'Cesky Krumlov',
	'Decin (Breziny, Libverda)',
	'Domazlice',
	'Frydek-Mistek',
	'Havlickuv Brod',
	'Hodonin',
	'Hradec Kralove',
	'Cheb',
	'Chomutov (Ervenice)',
	'Chrudim',
	'Jablonec nad Nisou (Liberec)',
	'Jicin (Liban)',
	'Jihlava',
	'Jindrichuv Hradec',
	'Karlovy Vary',
	'Karvina',
	'Kladno (Lany)',
	'Klatovy',
	'Kolin',
	'Kromeriz',
	'Kutna Hora',
	'Liberec',
	'Litomerice',
	'Louny (Lenesice)',
	'Melnik',
	'Mlada Boleslav',
	'Most (Ervenice)',
	'Nachod (Kleny)',
	'Novy Jicin',
	'Nymburk (Podebrady)',
	'Olomouc',
	'Opava',
	'Ostrava',
	'Pardubice',
	'Pelhrimov',
	'Pisek',
	'Plzen',
	'Praha (Karlov)',
	'Prachatice',
	'Prostejov',
	'Prerov',
	'Pribram',
	'Rakovnik',
	'Rokycany',
	'Rychnov nad Kneznou (Slatina)',
	'Semily (Libstat)',
	'Sokolov',
	'Strakonice',
	'Svidnik',
	'Svitavy (Moravská Trebova)',
	'Sumperk',
	'Tabor',
	'Tachov (Stribro)',
	'Teplice',
	'Trutnov',
	'Trebic (Bitovanky)',
	'Uherske Hradiste (Buchlovice)',
	'Usti nad Labem',
	'Usti nad Orlici',
	'Vestin',
	'Vyskov',
	'Zlin (Napajedla)'
]
type LanguageCodeHreflang = 'cs' | 'de' | 'en' | 'it' | 'pl' | 'sk'
type Organization = {
	[key: string]: {
		id: number
		uid: string
		countryCode: false | 'CZ' | 'DE' | 'PL' | 'IT'
		currencyCode: string
		lang: string
		hreflang: LanguageCodeHreflang
		webUrl: string
		domain: string
	}
}

export const ORGANIZATION: Organization = {
	WOLTAIR_CZ: {
		id: 1,
		uid: '0635d6fb-503a-11ec-9b80-42010afbd007',
		countryCode: 'CZ',
		currencyCode: 'CZK',
		lang: 'CZ',
		hreflang: 'cs',
		webUrl: 'https://www.woltair.cz/',
		domain: 'Woltair.cz'
	},
	WOLTAIR_PL: {
		id: 2,
		uid: '3ae478a7-b037-4c20-aaaa-9b1112a01e78',
		countryCode: 'PL',
		currencyCode: 'PLN',
		lang: 'PL',
		hreflang: 'pl',
		webUrl: 'https://www.woltair.pl/',
		domain: 'Woltair.pl'
	},
	WOLTAIR_DE: {
		id: 7,
		uid: '46a97804-8d3e-4a96-a763-bdfa64f5384c',
		countryCode: 'DE',
		currencyCode: 'EUR',
		lang: 'DE',
		hreflang: 'de',
		webUrl: 'https://www.woltair.de/',
		domain: 'Woltair.de'
	},
	WOLTAIR_IT: {
		id: 8,
		uid: '4eaa35e8-7df2-4c8e-a410-7642cd6d278b',
		countryCode: 'IT',
		currencyCode: 'EUR',
		lang: 'IT',
		hreflang: 'it',
		webUrl: 'https://www.woltair.it/',
		domain: 'Woltair.it'
	}
}

export const SELFSERVICE =
	{
		WOLTAIR_CZ: 'selfservice',
		WOLTAIR_PL: 'selfservice',
		WOLTAIR_DE: 'selfservice',
		WOLTAIR_IT: 'selfservice'
	}[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales] || 'selfservice'

export const STEP = {
	WOLTAIR_CZ: 'krok',
	WOLTAIR_PL: 'krok',
	WOLTAIR_DE: 'schritt',
	WOLTAIR_IT: 'passo'
}[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const SHOWROOM_VARS = {
	CS: {
		address: 'Na Radosti 399, Praha - Zličín',
		phoneNumber: '+420800887722',
		mapUrl:
			'https://www.google.com/maps/place/Na+Radosti+399,+155+21+Zli%C4%8D%C3%ADn/@50.0559353,14.2778541,17z/data=!3m1!4b1!4m5!3m4!1s0x470bbe7ac608e9cf:0xf797d76c571e3905!8m2!3d50.0559353!4d14.2800481?shorturl=1'
	}
}

/* should be temporary */
export const CONTACT_VARS: { [key: string]: { [key: string]: string } } = {
	WOLTAIR_CZ: {
		phoneNum: '800 88 77 22',
		phoneRef: 'tel:+420800887722',
		mail: 'info@woltair.cz',
		legalData: 'Woltair s.r.o. – IČ: 06770525| DIČ: CZ06770525',
		companyName: 'Woltair s.r.o.'
	},
	WOLTAIR_PL: {
		phoneNum: '+48 732 059 039',
		phoneRef: 'tel:+48732059039',
		mail: 'info@woltair.pl',
		legalData: 'Woltair.pl Sp. z o.o. – Regon: 388673161| NIP: 7011028945',
		companyName: 'Woltair.pl Sp. z o.o.'
	},
	WOLTAIR_DE: {
		phoneNum: '0800 865 1000',
		phoneRef: 'tel:+4908008651000',
		mail: 'info@woltair.de',
		legalData: 'Woltair Heat & Power Germany GmbH HRB 279801 ',
		companyName: 'Woltair Heat & Power Germany GmbH'
	},
	WOLTAIR_IT: {
		phoneNum: '02 83967781',
		phoneRef: 'tel:+390283967781',
		mail: 'info@woltair.it',
		companyName: 'Woltair Italia Srl',
		legalData: 'Woltair Italia Srl REA MI - 2667730 Codice fiscale 12511930963'
	}
}

export const PHONE_PREFIX =
	{
		WOLTAIR_CZ: '+420',
		WOLTAIR_PL: '+48',
		WOLTAIR_DE: '+49',
		WOLTAIR_IT: '+39'
	}[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales] || '+420'

/* should be temporary */
export const COMP_REPRESENTATIVE = {
	CS: {
		name: 'Jan Kadlec',
		position: 'Country Manager',
		mail: 'jan.kadlec@woltair.cz',
		imageUrl: '/images/persons/jan_kadlec.png'
	}
	/*
	DE: {
		name: 'Jan Hanuš',
		position: 'CEO',
		mail: 'jan.hanus@woltair.com',
		imageUrl: '/images/about/jan_hanus.webp'
	}*/
}

const REPRESENTATIVES_INIT = {
	FEEDBACK: {
		WOLTAIR_CZ: null,
		WOLTAIR_DE: null,
		WOLTAIR_PL: null,
		WOLTAIR_IT: {
			avatarSrc: '/images/avatars/emna.webp',
			name: 'Emna Neifar',
			position: 'Country Manager',
			phoneFormatted: '340 4767646',
			phone: '34047676466'
		}
	}
}
export const REPRESENTATIVES_FEEDBACK =
	REPRESENTATIVES_INIT.FEEDBACK[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales] || null

export const SOCIAL_MEDIA_LINKS = [
	{
		name: 'Facebook',
		url: {
			WOLTAIR_CZ: 'https://www.facebook.com/Woltair.cz/',
			WOLTAIR_PL: null,
			WOLTAIR_DE: 'https://www.facebook.com/WoltairDeutschland',
			WOLTAIR_IT: 'https://www.facebook.com/woltair.it/'
		},
		icons: {
			footer: '/images/icons/linear/facebook.svg'
		}
	},
	{
		name: 'Instagram',
		url: {
			WOLTAIR_CZ: 'https://www.instagram.com/woltair.cz/',
			WOLTAIR_PL: null,
			WOLTAIR_DE: 'https://www.instagram.com/woltair.de/',
			WOLTAIR_IT: 'https://www.instagram.com/woltair.it/'
		},
		icons: {
			footer: '/images/icons/linear/instagram.svg'
		}
	},
	{
		name: 'LinkedIn',
		url: {
			WOLTAIR_CZ: 'https://www.linkedin.com/company/18411297/',
			WOLTAIR_PL: null,
			WOLTAIR_DE: 'https://www.linkedin.com/company/woltair-de',
			WOLTAIR_IT: 'https://www.linkedin.com/company/woltair-it'
		},
		icons: {
			footer: '/images/icons/linear/linkedin.svg'
		}
	},
	{
		name: 'YouTube',
		url: {
			WOLTAIR_CZ: 'https://www.youtube.com/channel/UCEmagQV8mtRfLN4xQBAsj-A',
			WOLTAIR_PL: null,
			WOLTAIR_DE: null,
			WOLTAIR_IT: null
		},
		icons: {
			footer: '/images/icons/linear/youtube.svg'
		}
	},
	{
		name: 'Twitter',
		url: {
			WOLTAIR_CZ: 'https://twitter.com/woltaircz',
			WOLTAIR_PL: null,
			WOLTAIR_DE: null,
			WOLTAIR_IT: null
		},
		icons: {
			footer: '/images/icons/linear/twitter.svg'
		}
	}
]

type AcademyContacts = {
	[key: string]:
		| {
				title: string
				card: {
					name: string
					email: string
				}
				address: string
				email: string
		  }
		| object
}

export const ACADEMY_CONTACTS: AcademyContacts = {
	WOLTAIR_CZ: {
		title: 'Woltair academy&nbsp;& showroom',
		card: {
			name: 'Jitka Jirsáková',
			email: 'academy@woltair.cz'
		},
		address: 'Na Radosti 399, Praha - Zličín',
		email: 'academy@woltair.cz'
	},
	WOLTAIR_PL: {
		title: 'Akademia Woltair',
		address: 'Szyszkowa 35/37, Warszawa',
		email: 'akademia@woltair.pl'
	},
	WOLTAIR_DE: {
		title: 'Woltair Academy & Showroom',
		address: 'Erdinger Straße 35, 81829 München',
		email: 'partner@woltair.de'
	},
	WOLTAIR_IT: {
		title: 'Woltair Academy & Showroom',
		address: 'Via Galileo Ferraris, 125/B, 20021 Bollate MI',
		email: 'academy@woltair.it'
	}
}

export const ACADEMY_LOCATIONS = {
	WOLTAIR_CZ: ['PRAHA_ZLICIN', 'BRNO_VUT'],
	WOLTAIR_PL: ['WARSZAWA_ACADEMY'],
	WOLTAIR_DE: ['MUNCHEN_ACADEMY'],
	WOLTAIR_IT: ['BOLLATE_ACADEMY']
}

export const CAREERS_EMAIL = {
	WOLTAIR_CZ: 'kariera@woltair.cz',
	WOLTAIR_PL: 'kariera@woltair.cz',
	WOLTAIR_DE: 'jobs@woltair.de',
	WOLTAIR_IT: 'jobs@woltair.it'
}

export const SMARTLOOK_ID_INIT = {
	WOLTAIR_CZ: 'af1f06929a1703cfd7c02a25875e2516eeca96c0',
	WOLTAIR_PL: null,
	WOLTAIR_DE: 'ed2d29b5ab8527d1d41acb142e455e7fad21639e',
	WOLTAIR_IT: '82279dc34e7c784d1e2a8a28034608e45d4ea195'
}
export const SMARTLOOK_ID = SMARTLOOK_ID_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const COLORS = {
	NEUTRAL: 'NEUTRAL',
	CONTRAST: 'CONTRAST',
	ACCENT_CONTRAST: 'ACCENT-CONTRAST',
	PRIMARY: 'PRIMARY',
	BLUE: 'BLUE',
	RED: 'RED',
	GREEN: 'GREEN',
	YELLOW: 'YELLOW',
	ORANGE: 'ORANGE',
	POSITIVE: 'POSITIVE',
	WARNING: 'WARNING',
	NEGATIVE: 'NEGATIVE'
}

export const MEDIA_QUERY = {
	XS: '480px',
	SM: '600px',
	MD: '840px',
	LG: '960px',
	XL: '1280px',
	XXL: '1440px'
}

export const LAYOUT_TYPE = {
	DEFAULT: 'DEFAULT',
	DETAIL: 'DETAIL'
}

export const PAIRING_INVOICE_ITEM_TYPE = {
	MATERIAL: 'MATERIAL',
	BONUS: 'BONUS',
	OBALY: 'OBALY',
	PRACE: 'PRACE',
	SKONTO: 'SKONTO',
	ZALOHA: 'ZALOHA',
	NARADI: 'NARADI'
}

export const DIRECTION = {
	ASC: 'asc',
	DESC: 'desc'
}

export const THEMES = ['superfix-light', 'superfix-dark', 'woltair-light']

export const ICON_PREFIX = 'mdi mdi-'
export const ICON_SIZE_PREFIX = 'mdi-'
export const ICON_SIZE_POSTFIX = 'px'
export const ICON_SIZES = {
	SM: 24,
	MD: 36,
	LG: 48
}

export const IMAGE_PRELOAD_ATTEMPT_DELAY = 1000
export const MAX_IMAGE_PRELOAD_ATTEMPTS = 3

export const FORM_STATES = {
	NEW: 'NEW',
	FINISHED: 'FINISHED'
}

export const POINTING_DIRECTIONS = {
	UP: 'up',
	RIGHT: 'right',
	DOWN: 'down',
	LEFT: 'left'
}

export const ESC_CODES = ['Escape', 'Esc']
export const ESC_KEY = 27
export interface RegionEnvItem {
	name: string
	value: string
	url?: string
	code?: string
}

export const REGIONS = {
	WOLTAIR_CZ: [
		{
			name: 'Praha',
			value: 'Hlavní město Praha',
			url: 'praha',
			code: 'PHA'
		},
		{
			name: 'Jihočeský kraj',
			value: 'Jihočeský kraj',
			url: 'jihocesky-kraj',
			code: 'JHC'
		},
		{
			name: 'Jihomoravský kraj',
			value: 'Jihomoravský kraj',
			url: 'jihomoravsky-kraj',
			code: 'JHM'
		},
		{ name: 'Kraj Vysočina', value: 'Kraj Vysočina', url: 'kraj-vysocina', code: 'VYS' },
		{
			name: 'Liberecký kraj',
			value: 'Liberecký kraj',
			url: 'liberecky-kraj',
			code: 'LBK'
		},
		{
			name: 'Karlovarský kraj',
			value: 'Karlovarský kraj',
			url: 'karlovarsky-kraj',
			code: 'KVH'
		},
		{
			name: 'Královéhradecký kraj',
			value: 'Královéhradecký kraj',
			url: 'kralovehradecky-kraj',
			code: 'HKK'
		},
		{
			name: 'Moravskoslezský kraj',
			value: 'Moravskoslezský kraj',
			url: 'moravskoslezsky-kraj',
			code: 'MSK'
		},
		{
			name: 'Olomoucký kraj',
			value: 'Olomoucký kraj',
			url: 'olomoucky-kraj',
			code: 'OLK'
		},
		{
			name: 'Pardubický kraj',
			value: 'Pardubický kraj',
			url: 'pardubicky-kraj',
			code: 'PAK'
		},
		{
			name: 'Plzeňský kraj',
			value: 'Plzeňský kraj',
			url: 'plzensky-kraj',
			code: 'PLK'
		},
		{
			name: 'Středočeský kraj',
			value: 'Středočeský kraj',
			url: 'stredocesky-kraj',
			code: 'STC'
		},
		{
			name: 'Ústecký kraj',
			value: 'Ústecký kraj',
			url: 'ustecky-kraj',
			code: 'ULK'
		},
		{
			name: 'Zlínský kraj',
			value: 'Zlínský kraj',
			url: 'zlinsky-kraj',
			code: 'ZLK'
		}
	],
	WOLTAIR_PL: [
		{
			name: 'Dolnośląskie',
			value: 'Województwo dolnośląskie'
		},
		{
			name: 'Kujawsko-pomorskie',
			value: 'Województwo kujawsko-pomorskie'
		},
		{
			name: 'Lubelskie',
			value: 'Województwo lubelskie'
		},
		{
			name: 'Lubuskie',
			value: 'Województwo lubuskie'
		},
		{
			name: 'Łódzkie',
			value: 'Województwo łódzkie'
		},
		{
			name: 'Małopolskie',
			value: 'Województwo małopolskie'
		},
		{
			name: 'Mazowieckie',
			value: 'Województwo mazowieckie'
		},
		{
			name: 'Opolskie',
			value: 'Województwo opolskie'
		},
		{
			name: 'Podkarpackie',
			value: 'Województwo podkarpackie'
		},
		{
			name: 'Podlaskie',
			value: 'Województwo podlaskie'
		},
		{
			name: 'Pomorskie',
			value: 'Województwo pomorskie'
		},
		{
			name: 'Śląskie',
			value: 'Województwo śląskie'
		},
		{
			name: 'Świętokrzyskie',
			value: 'Województwo świętokrzyskie'
		},
		{
			name: 'Warmińsko-mazurskie',
			value: 'Województwo warmińsko-mazurskie'
		},
		{
			name: 'Wielkopolskie',
			value: 'Województwo wielkopolskie'
		},
		{
			name: 'Zachodniopomorskie',
			value: 'Województwo zachodniopomorskie'
		}
	],
	WOLTAIR_DE: [
		{
			name: 'Bremen',
			value: 'Bremen'
		},
		{
			name: 'Schwerin',
			value: 'Schwerin'
		},
		{
			name: 'Potsdam',
			value: 'Potsdam'
		},
		{
			name: 'Magdeburg',
			value: 'Magdeburg'
		},
		{
			name: 'Saarbrücken',
			value: 'Saarbrücken'
		},
		{
			name: 'Mainz',
			value: 'Mainz'
		},
		{
			name: 'Erfurt',
			value: 'Erfurt'
		},
		{
			name: 'Kiel',
			value: 'Kiel'
		},
		{
			name: 'Dresden',
			value: 'Dresden'
		},
		{
			name: 'Leipzig',
			value: 'Leipzig'
		},
		{
			name: 'Hanover',
			value: 'Hanover'
		},
		{
			name: 'Düsseldorf',
			value: 'Düsseldorf'
		},
		{
			name: 'Stuttgart',
			value: 'Stuttgart'
		},
		{
			name: 'Hesse',
			value: 'Hesse'
		},
		{
			name: 'Bavaria',
			value: 'Bavaria'
		},
		{
			name: 'Hamburg',
			value: 'Hamburg'
		},
		{
			name: 'Berlin',
			value: 'Berlin'
		}
	],
	WOLTAIR_IT: [
		{
			value: 'Abruzzo',
			name: 'Abruzzo',
			url: 'abruzzo',
			code: 'ABR'
		},
		{
			value: 'Basilicata',
			name: 'Basilicata',
			url: 'basilicata',
			code: 'BAS'
		},
		{
			value: 'Calabria',
			name: 'Calabria',
			url: 'calabria',
			code: 'CAL'
		},
		{
			value: 'Campania',
			name: 'Campania',
			url: 'campania',
			code: 'CAM'
		},
		{
			value: 'Emilia Romagna',
			name: 'Emilia Romagna',
			url: 'emilia-romagna',
			code: 'EMR'
		},
		{
			value: 'Friuli Venezia Giulia',
			name: 'Friuli Venezia Giulia',
			url: 'friuli-venezia-giulia',
			code: 'FVG'
		},
		{
			value: 'Lazio',
			name: 'Lazio',
			url: 'lazio',
			code: 'LAZ'
		},
		{
			value: 'Liguria',
			name: 'Liguria',
			url: 'liguria',
			code: 'LIG'
		},
		{
			value: 'Lombardia',
			name: 'Lombardia',
			url: 'lombardia',
			code: 'LOM'
		},
		{
			value: 'Marche',
			name: 'Marche',
			url: 'marche',
			code: 'MAR'
		},
		{
			value: 'Molise',
			name: 'Molise',
			url: 'molise',
			code: 'MOL'
		},
		{
			value: 'Piemonte',
			name: 'Piemonte',
			url: 'piemonte',
			code: 'PIE'
		},
		{
			value: 'Puglia',
			name: 'Puglia',
			url: 'puglia',
			code: 'PUG'
		},
		{
			value: 'Sardegna',
			name: 'Sardegna',
			url: 'sardegna',
			code: 'SAR'
		},
		{
			value: 'Sicilia',
			name: 'Sicilia',
			url: 'sicilia',
			code: 'SIC'
		},
		{
			value: 'Toscana',
			name: 'Toscana',
			url: 'toscana',
			code: 'TOS'
		},
		{
			value: 'Trentino-Alto Adige',
			name: 'Trentino-Alto Adige',
			url: 'trentino-alto-adige',
			code: 'TAA'
		},
		{
			value: 'Umbria',
			name: 'Umbria',
			url: 'umbria',
			code: 'UMB'
		},
		{
			value: "Valle d'Aosta",
			name: "Valle d'Aosta",
			url: 'valle-daosta',
			code: 'VDA'
		},
		{
			value: 'Veneto',
			name: 'Veneto',
			url: 'veneto',
			code: 'VEN'
		}
	]
}
export const REGIONS_ENV: RegionEnvItem[] = REGIONS[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

const REGIONS_UNSERVICED_INIT = {
	WOLTAIR_CZ: null,
	WOLTAIR_DE: null,
	WOLTAIR_PL: null,
	WOLTAIR_IT: [
		{
			value: 'Abruzzo',
			name: 'Regione Abruzzo',
			url: 'abruzzo',
			code: 'ABR'
		},
		{
			value: 'Basilicata',
			name: 'Regione Basilicata',
			url: 'basilicata',
			code: 'BAS'
		},
		{
			value: 'Calabria',
			name: 'Regione Calabria',
			url: 'calabria',
			code: 'CAL'
		},
		{
			value: 'Campania',
			name: 'Regione Campania',
			url: 'campania',
			code: 'CAM'
		},
		{
			value: 'Lazio',
			name: 'Regione Lazio',
			url: 'lazio',
			code: 'LAZ'
		},
		{
			value: 'Marche',
			name: 'Regione Marche',
			url: 'marche',
			code: 'MAR'
		},
		{
			value: 'Molise',
			name: 'Regione Molise',
			url: 'molise',
			code: 'MOL'
		},
		{
			value: 'Puglia',
			name: 'Regione Puglia',
			url: 'puglia',
			code: 'PUG'
		},
		{
			value: 'Sardegna',
			name: 'Regione Sardegna',
			url: 'sardegna',
			code: 'SAR'
		},
		{
			value: 'Sicilia',
			name: 'Regione Sicilia',
			url: 'sicilia',
			code: 'SIC'
		},
		{
			value: 'Toscana',
			name: 'Regione Toscana',
			url: 'toscana',
			code: 'TOS'
		},
		{
			value: 'Trentino-Alto Adige',
			name: 'Regione Trentino-Alto Adige',
			url: 'trentino-alto-adige',
			code: 'TAA'
		},
		{
			value: 'Umbria',
			name: 'Regione Umbria',
			url: 'umbria',
			code: 'UMB'
		},
		{
			value: "Valle d'Aosta",
			name: "Regione Valle d'Aosta",
			url: 'valle-daosta',
			code: 'VDA'
		}
	]
}
export const REGIONS_UNSERVICED = REGIONS_UNSERVICED_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const DEVICE_TYPES = {
	FOTOVOLTAIKA: 'FOTOVOLTAIKA',
	TEPELNE_CERPADLO: 'TEPELNE_CERPADLO'
}

export const HOUSE_HEAT_TYPE = {
	COAL_I_II: 'COAL_I_II',
	COAL: 'COAL',
	GAS: 'GAS',
	ELECTRO: 'ELECTRO',
	HEATPUMP: 'HEATPUMP'
}

export const ISO_LEVEL = {
	HIGH: 'HIGH',
	MEDIUM: 'MEDIUM',
	LOW: 'LOW',
	NO: 'NO'
}

export const CUSTOM_ISO_ROOF = {
	0: 0,
	5: 5,
	10: 10,
	15: 15,
	20: 20,
	30: 30,
	40: 40,
	50: 50
}

export const CUSTOM_ISO_FACADE = {
	0: 0,
	5: 5,
	10: 10,
	15: 15,
	20: 20,
	25: 25,
	30: 30
}

export const CUSTOM_ISO_WINDOW = {
	WOODEN: 'WOODEN',
	DOUBLE: 'DOUBLE',
	TRIPLE: 'TRIPLE'
}

export const WEB_MEASUREMENT_EVENT_LENGTH = 90
export const MEETINGS_MIN_BUSINESS_DAYS_FROM_NOW = 4
export const MEETINGS_MAX_TRAVEL_TIME = 90
export const MEETINGS_MAX_TRAVEL_DISTANCE = 100

export const PERSONAL_MEETING_FREE = {
	cs: true,
	PL: true
}

export const QUALIFICATION_CODE = {
	MEETING_HVAC: 'MEETING_HVAC',
	MEETING_FVE: 'MEETING_FVE',
	CODE_12: 'CODE_12', // Topenář
	CODE_58: 'CODE_58', // Předávací technik
	CODE_13: 'CODE_13', // Chlaďař
	CODE_51: 'CODE_51', // Elektro
	CODE_23: 'CODE_23', // Betonář
	CODE_22: 'CODE_22', // Zedník (drobné)
	CODE_21: 'CODE_21', // Zedník (bourací)
	CODE_27: 'CODE_27', // Servisní technik (UdP)
	CODE_26: 'CODE_26', // Servisní technik (MaR)
	CODE_29: 'CODE_29', // Plynař
	CODE_32: 'CODE_32', // Revizák (plyn)
	CODE_45: 'CODE_45', // Autogen
	CODE_44: 'CODE_44', // Jádrové vrt
	CODE_53: 'CODE_53', // PDL + OS
	CODE_52: 'CODE_52', // BZ
	CODE_42: 'CODE_42', // Střechař FVE
	CODE_41: 'CODE_41', // Elektrikář FVE
	CODE_43: 'CODE_43' // Revizák FVE
}

export const PLUTO_TERM_TYPE = {
	PHYSICAL: 'PHYSICAL',
	VIRTUAL: 'VIRTUAL',
	HALF_VIRTUAL: 'HALF_VIRTUAL'
}

export const MEASUREMENT_TYPE = {
	PERSONAL: 'PERSONAL',
	OFFICE: 'OFFICE',
	WHATSAPP: 'WHATSAPP',
	PHONE_MEASUREMENT: 'PHONE_MEASUREMENT',
	PRESENTATION: 'PRESENTATION',
	PRESENTATION_CALL: 'PRESENTATION_CALL',
	PRESENTATION_VIDEO: 'PRESENTATION_VIDEO',
	PHONE: 'PHONE',
	PHONE_CONSULTATION: 'PHONE_CONSULTATION'
}

export const PLUTO_FEE_TYPE = {
	ZERO: 'ZERO',
	REGULAR: 'REGULAR',
	WEEKDAYS: 'WEEKDAYS',
	STATE_HOLIDAYS: 'STATE_HOLIDAYS',
	SPECIAL: 'SPECIAL'
}

export const ORDER_GROUP = {
	HVAC: 'HVAC',
	FVE: 'FVE'
}

export const ACADEMY_LOCALITIES = {
	BRNO_VUT: 'Brno - VUT',
	PRAHA_ZLICIN: 'Praha - Zličín',
	WARSZAWA_ACADEMY: 'Warszawa',
	MUNCHEN_ACADEMY: 'München',
	BOLLATE_ACADEMY: 'Bollate',
	ONLINE: 'Online'
}

export const EVENT_TYPE = {
	GENERIC: 'GENERIC',
	INSTALLATION: 'INSTALLATION',
	MEASUREMENT: 'MEASUREMENT',
	SERVIS: 'SERVIS',
	OTHER_JOB: 'OTHER_JOB',
	VACATION: 'VACATION',
	DOCTOR: 'DOCTOR',
	PARTNER_ORDER: 'PARTNER_ORDER',
	PLUTO: 'PLUTO',
	PLUTO_TRANSPORT_TO: 'PLUTO_TRANSPORT_TO',
	PLUTO_TRANSPORT_FROM: 'PLUTO_TRANSPORT_FROM',
	HANDOVER: 'HANDOVER'
}

export const INSTALLATION_CATEGORY = {
	TEPELNE_CERPADLO: 'TEPELNE_CERPADLO',
	PLYNOVY_KOTEL: 'PLYNOVY_KOTEL',
	ELEKTROKOTEL: 'ELEKTROKOTEL',
	KLIMATIZACE: 'KLIMATIZACE',
	OTOPNA_SOUSTAVA: 'OTOPNA_SOUSTAVA',
	REKUPERACE: 'REKUPERACE',
	SOLARNI_SYSTEM: 'SOLARNI_SYSTEM',
	PODLAHOVE_TOPENI: 'PODLAHOVE_TOPENI',
	ROZVODY_VODY_A_KANALIZACE: 'ROZVODY_VODY_A_KANALIZACE',
	ZASOBNIK_TEPLE_VODY: 'ZASOBNIK_TEPLE_VODY',
	FOTOVOLTAIKA: 'FOTOVOLTAIKA',
	PROJEKCNI_CINNOST: 'PROJEKCNI_CINNOST',
	DOBIJECI_STANICE: 'DOBIJECI_STANICE',
	OTHER: 'OTHER'
}

export const OFFER_URL = {
	cs: 'nabidka',
	PL: 'zapytanie'
}

export const INSTALLATION_CATEGORY_URL = {
	cs: {
		TEPELNE_CERPADLO: 'tepelna-cerpadla',
		FOTOVOLTAIKA: 'fotovoltaika',
		DOBIJECI_STANICE: 'fotovoltaika'
	},
	PL: {
		TEPELNE_CERPADLO: 'pompy-ciepla',
		FOTOVOLTAIKA: 'fotowoltaika'
	}
}

export const PHONE_CONSULTATION_URL = {
	cs: 'telefonni-konzultace',
	PL: 'konsultacja-telefoniczna'
}

export const WOLT_ADVISOR_URL = {
	cs: 'kalkulacka',
	PL: 'kalkulator'
}

export const PERSONAL_MEETING_URL = {
	cs: 'schuzka',
	PL: 'spotkanie'
}

export const FVE_OFFER_TYPES = {
	OPTIMUM: 'OPTIMUM',
	SELF_SUFFICIENCY: 'SELF_SUFFICIENCY'
}

export const FVE_COMPONENT_TYPE = {
	PANEL: 'PANEL',
	INVERTER: 'INVERTER',
	BATTERY: 'BATTERY',
	OPTIMIZER: 'OPTIMIZER',
	WATTROUTER: 'WATTROUTER',
	WALLBOX: 'WALLBOX',
	ROOF: 'ROOF'
}

export const ADDRESS_KEY = {
	STREET: ['route', 'locality'],
	STREET_NUMBER: ['street_number', 'premise', 'establishment'],
	CITY: ['sublocality_level_1', 'locality'],
	ZIP: 'postal_code',
	COUNTRY: 'country',
	REGION: 'administrative_area_level_1'
}

export const FINALIZATION_REPORT_PRODUCT_TYPE = {
	WORK: 'WORK',
	MATERIAL: 'MATERIAL'
}

export const CATALOG_ITEM_TYPE = {
	PACKAGE: 'PACKAGE',
	PRODUCT: 'PRODUCT'
}

export const WEB_URL_LOCALIZED = {
	cs: 'web.cz',
	PL: 'web/pl'
}

export const ENVIRONMENTS = {
	PRODUCTION: 'production',
	STAGING: 'staging',
	DEV: 'dev',
	LOCAL: 'local'
}

export const EXPERT_ADVICE_CATEGORIES_ORDER = {
	CZ: [
		'Tepelná čerpadla',
		'Fotovoltaika',
		'Nabíjecí stanice',
		'Dotace',
		'Vytápění domu',
		'Servis a opravy'
	]
}

export const BLOG_CATEGORIES_ORDER = {
	CZ: [
		'Tepelná čerpadla',
		'Fotovoltaika',
		'Nabíjecí stanice',
		'Dotace',
		'Vytápění domu',
		'Klimatizace',
		'Ohřev vody',
		'Servis a opravy',
		'O nás'
	]
}

export const ACADEMY_CATEGORIES_ORDER = {
	CZ: ['Tepelná čerpadla', 'Vytápění a hydraulika', 'Fotovoltaika']
}

export const ADDRESS_TYPE = {
	COORDINATES: 'COORDINATES',
	FULL_ADDRESS: 'FULL_ADDRESS'
}

export const AFFIL_CODE = {
	CS: 'ceskasporitelna'
}

export const LEAD_TYPE = {
	INSTALLATION: 'INSTALLATION',
	SERVICE: 'SERVICE',
	REVISION: 'REVISION'
}

export const SERVICE_TYPE = {
	SERVICE: 'SERVICE',
	REVISION: 'REVISION'
}

export const DEVICE_TYPE = {
	TC: 'TC',
	PK: 'PK',
	SS: 'SS',
	KL: 'KL',
	EK: 'EK'
}

export const HEAT_PUMP_SYSTEM = {
	AIR_TO_WATER: 'AIR_TO_WATER',
	GROUND_TO_WATER: 'GROUND_TO_WATER',
	AIR_TO_AIR: 'AIR_TO_AIR',
	WATER_TO_WATER: 'WATER_TO_WATER'
}

export const LEAD_SOURCE = {
	NAVIGATION_BUTTON: 'NAVIGATION_BUTTON',
	WOLTADVISOR_PV: 'WOLTADVISOR_PV',
	WOLTADVISOR_HVAC: 'WOLTADVISOR_HVAC',
	PERSONAL_MEETING_HVAC: 'PERSONAL_MEETING_HVAC',
	PERSONAL_MEETING_PV: 'PERSONAL_MEETING_PV',
	MARKETPLACE_EXPERT: 'MARKETPLACE_EXPERT',
	HVAC_MARKETPLACE: 'HVAC_MARKETPLACE',
	HVAC_BLOG: 'HVAC_BLOG',
	HVAC_MARKETPLACE_SHOW: 'HVAC_MARKETPLACE_SHOW',
	HVAC_MARKETPLACE_EXPERT: 'HVAC_MARKETPLACE_EXPERT',
	HVAC_MARKETPLACE_SIMPLE: 'HVAC_MARKETPLACE_SIMPLE',
	HVAC_MARKETPLACE_GUARDDOG: 'HVAC_MARKETPLACE_GUARDDOG',
	PV_MARKETPLACE: 'PV_MARKETPLACE',
	PV_BLOG: 'PV_BLOG',
	PV_MARKETPLACE_SHOW: 'PV_MARKETPLACE_SHOW',
	PV_MARKETPLACE_EXPERT: 'PV_MARKETPLACE_EXPERT',
	PV_MARKETPLACE_SIMPLE: 'PV_MARKETPLACE_SIMPLE',
	PV_MARKETPLACE_GUARDDOG: 'PV_MARKETPLACE_GUARDDOG'
}

export const LEAD_SOURCES_INIT = {
	WOLTAIR_CZ: {
		FINANCING: 'Financování'
	},
	WOLTAIR_PL: {
		FINANCING: 'Finansowanie'
	},
	WOLTAIR_DE: null,
	WOLTAIR_IT: null
}
export const LEAD_SOURCES = LEAD_SOURCES_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const LEAD_STATE = {
	NEW: 'NEW',
	IN_PROGRESS: 'IN_PROGRESS',
	CONVERTED: 'CONVERTED',
	LOST: 'LOST',
	DUPLICITY: 'DUPLICITY'
}

export const COUNTRY_CODE_BY_LOCALE = {
	cs: 'CZ',
	PL: 'PL'
}

export const VAT = {
	WOLTAIR_CZ: {
		default: { value: '15', label: '15%', name: '15%' },
		21: { value: '21', label: '21%', name: '21%' }
	},
	WOLTAIR_PL: {
		default: { value: '8', label: '8%', name: '8%' },
		23: { value: '23', label: '23%', name: '23%' }
	},
	WOLTAIR_DE: {
		default: { value: '19', label: '19%', name: '19%' },
		23: { value: '23', label: '23%', name: '23%' }
	},
	WOLTAIR_IT: {
		default: { value: '10', label: '10%', name: '10%' },
		22: { value: '22', label: '22%', name: '22%' }
	}
}

export const LOCALE = {
	cs: 'CS',
	PL: 'PL',
	EN: 'EN'
}

export const CURRENCY = {
	CZK: 'CZK',
	PLN: 'PLN'
}

export const CURRENCY_BY_COUNTRY = {
	WOLTAIR_CZ: 'CZK',
	WOLTAIR_PL: 'PLN',
	WOLTAIR_IT: 'EUR',
	WOLTAIR_DE: 'EUR'
}[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const ORDER_TYPE = {
	INSTALACE: 'INSTALACE',
	ZPROVOZNENI: 'ZPROVOZNENI',
	REVIZE: 'REVIZE',
	SERVIS: 'SERVIS',
	OTHER: 'OTHER'
}

export const CONTRACT_STATE = {
	NEW: 'NEW',
	NEEDS_APPROVAL: 'NEEDS_APPROVAL',
	READY_TO_SIGN: 'READY_TO_SIGN',
	APPROVED: 'APPROVED',
	FINISHED: 'FINISHED',
	REFUSED: 'REFUSED',
	INVALID: 'INVALID'
}

export const TODO_STATE = {
	OPEN: 'OPEN',
	RESOLVED: 'RESOLVED',
	CANCELLED: 'CANCELLED'
}

export const PAIRING_INVOICE_STATE = {
	TODO: 'TODO',
	LOCKED: 'LOCKED',
	DONE: 'DONE',
	SYNCED: 'SYNCED',
	SYNC_FAILED: 'SYNC_FAILED'
}

export const AUTOMATION_PROPERTY_VALUE_TYPE = {
	ENUM: 'ENUM',
	STRING: 'STRING'
}

export const ORDER_STATES = [
	'NEW',
	'NEW_IN_PROGRESS',
	'ZAMERENI',
	'FOR_PRESALES_NEW',
	'FOR_PRESALES_BACK',
	'FOR_PRESALES_OLD',
	'IN_PRESALES',
	'PRESALES_DONE',
	'QUOTE_PREPARATION',
	'PRESENTATION_MEETING',
	'CUSTOMER_OFFER',
	'CONTRACT_PREPARATION',
	'CONFIRMED_PROJECT',
	'CUSTOMER_CONTRACT',
	'CONTRACT_SIGNED',
	'CONFIRMED',
	'CONFIRMED_CHANGING',
	'REALIZATION_POSTPONED',
	'PREPARED',
	'WORKING',
	'LONG_TERM_WORK',
	'WORK_DONE',
	'FINISHED_AND_CLAIMED',
	'FINISHED',
	'FINISHED_AND_PAID',
	'REJECTED'
]

export const ORDER_STATE = {
	NEW: 'NEW',
	NEW_IN_PROGRESS: 'NEW_IN_PROGRESS',
	ZAMERENI: 'ZAMERENI',
	FOR_PRESALES_NEW: 'FOR_PRESALES_NEW',
	FOR_PRESALES_BACK: 'FOR_PRESALES_BACK',
	FOR_PRESALES_OLD: 'FOR_PRESALES_OLD',
	IN_PRESALES: 'IN_PRESALES',
	PRESALES_DONE: 'PRESALES_DONE',
	QUOTE_PREPARATION: 'QUOTE_PREPARATION',
	PRESENTATION_MEETING: 'PRESENTATION_MEETING',
	CUSTOMER_OFFER: 'CUSTOMER_OFFER',
	CONTRACT_PREPARATION: 'CONTRACT_PREPARATION',
	CONFIRMED_PROJECT: 'CONFIRMED_PROJECT',
	CUSTOMER_CONTRACT: 'CUSTOMER_CONTRACT',
	CONTRACT_SIGNED: 'CONTRACT_SIGNED',
	CONFIRMED: 'CONFIRMED',
	CONFIRMED_CHANGING: 'CONFIRMED_CHANGING',
	REALIZATION_POSTPONED: 'REALIZATION_POSTPONED',
	PREPARED: 'PREPARED',
	WORKING: 'WORKING',
	LONG_TERM_WORK: 'LONG_TERM_WORK',
	WORK_DONE: 'WORK_DONE',
	FINISHED_AND_CLAIMED: 'FINISHED_AND_CLAIMED',
	FINISHED: 'FINISHED',
	FINISHED_AND_PAID: 'FINISHED_AND_PAID',
	REJECTED: 'REJECTED'
}

export const ORDER_STATES_CONDENSED = [
	['NEW', 'NEW_IN_PROGRESS'],
	['ZAMERENI'],
	[
		'FOR_PRESALES_NEW',
		'FOR_PRESALES_BACK',
		'FOR_PRESALES_OLD',
		'IN_PRESALES',
		'PRESALES_DONE',
		'QUOTE_PREPARATION',
		'PRESENTATION_MEETING'
	],
	['CUSTOMER_OFFER', 'CONTRACT_PREPARATION'],
	['CUSTOMER_CONTRACT', 'CONTRACT_SIGNED', 'CONFIRMED_PROJECT'],
	['CONFIRMED', 'CONFIRMED_CHANGING', 'REALIZATION_POSTPONED'],
	['PREPARED', 'WORKING', 'LONG_TERM_WORK'],
	['WORK_DONE', 'FINISHED_AND_CLAIMED'],
	['FINISHED'],
	['FINISHED_AND_PAID'],
	['REJECTED']
]

export const SF_SYSTEM_EMPLOYEE_ID = 128

export const CS_APRROVED_LIST = [
	'hsilhankova@csas.cz',
	'mstugelova@csas.cz',
	'ivanapospisilova@csas.cz',
	'mbehunkova@csas.cz',
	'mkorejtkova@csas.cz',
	'ipalatkova@csas.cz',
	'asedlakova@csas.cz',
	'arozehnalova@csas.cz',
	'mcoufalova@csas.cz',
	'martblahova@csas.cz',
	'test@cs.cz',
	'aantalova@csas.cz',
	'martin.kindl@woltair.cz'
]

export const REGEX_INIT = {
	WOLTAIR_CZ: {
		PHONE: /^(\+|\d)[\d\s]{8,}$/,
		ZIP: /^(?:(\d{5})|(\d{3} \d{2})|(\d{2}-\d{3})|(\d{5}))$/
	},
	WOLTAIR_DE: {
		PHONE: /^(\+|\d)[\d\s]{8,}$/,
		ZIP: /^(?:(\d{5})|(\d{3} \d{2})|(\d{2}-\d{3})|(\d{5}))$/
	},
	WOLTAIR_IT: {
		PHONE: /^(\+|\d)[\d\s]{8,}$/,
		ZIP: /^(?:(\d{5})|(\d{3} \d{2})|(\d{2}-\d{3})|(\d{5}))$/
	},
	WOLTAIR_PL: {
		PHONE: /^(\+|\d)[\d\s]{8,}$/,
		ZIP: /^(?:(\d{5})|(\d{3} \d{2})|(\d{2}-\d{3})|(\d{5}))$/
	}
}
export const REGEX = REGEX_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const MEETING_TIME = [
	{
		text: 'MORNING',
		from: 8,
		to: 11
	},
	{
		text: 'AFTERNOON',
		from: 12,
		to: 16
	},
	{
		text: 'EVENING',
		from: 16,
		to: 18
	}
]

export const CALL_ME_TIME = [
	{
		name: 'MORNING',
		from: {
			hours: 8,
			mins: 30
		},
		to: {
			hours: 12,
			mins: 0
		}
	},
	{
		name: 'AFTERNOON',
		from: {
			hours: 12,
			minutes: 0
		},
		to: {
			hours: 16,
			minutes: 30
		}
	},
	{
		name: 'ANYTIME'
	}
]

export const FINALIZATION_REPORT_APPROVAL_GROUP_TYPE = {
	CUSTOMER: 'CUSTOMER',
	CONTRACTOR: 'CONTRACTOR'
}

export const ADDRESS_TYPE_WHOUSE = {
	DELIVERY: 'DELIVERY',
	BILLING: 'BILLING'
}

export const CLIENT_STATE_WHOUSE = {
	PENDING: 'PENDING',
	DENIED: 'DENIED',
	APPROVED: 'APPROVED'
}

export const DELIVERY_TYPE = {
	PICK_UP: 'PICK_UP',
	TOPTRANS: 'TOPTRANS'
}

export const ORDER_SOURCE = {
	ESHOP: 'ESHOP',
	SUPERFIX_ORDER: 'SUPERFIX_ORDER',
	SUPERFIX_MATERIAL: 'SUPERFIX_MATERIAL'
}

export const PAYMENT_TYPE = {
	BANK_TRANSFER: 'BANK_TRANSFER'
}

export const WHOUSE_PICKUP_ADDRESS = {
	client: {
		name: 'Woltair s.r.o.'
	},
	houseNumber: '399',
	street: 'Na radosti',
	municipality: 'Praha, Zličín',
	zipCode: '155 21'
}

export const CITIES = {
	WOLTAIR_CZ: [
		{ name: 'Praha', slug: 'praha' },
		{ name: 'Brno', slug: 'brno' },
		{ name: 'Ostrava', slug: 'ostrava' },
		{ name: 'Plzeň', slug: 'plzen' },
		{ name: 'Liberec', slug: 'liberec' },
		{ name: 'Olomouc', slug: 'olomouc' },
		{ name: 'České Budějovice', slug: 'ceske-budejovice' },
		{ name: 'Hradec Králové', slug: 'hradec-kralove' },
		{ name: 'Ústí nad Labem', slug: 'usti-nad-labem' },
		{ name: 'Pardubice', slug: 'pardubice' },
		{ name: 'Zlín', slug: 'zlin' },
		{ name: 'Havířov', slug: 'havirov' },
		{ name: 'Kladno', slug: 'kladno' },
		{ name: 'Most', slug: 'most' },
		{ name: 'Opava', slug: 'opava' },
		{ name: 'Frýdek-Místek', slug: 'frydek-mistek' },
		{ name: 'Jihlava', slug: 'jihlava' },
		{ name: 'Karviná', slug: 'karvina' },
		{ name: 'Teplice', slug: 'teplice' },
		{ name: 'Děčín', slug: 'decin' },
		{ name: 'Chomutov', slug: 'chomutov' },
		{ name: 'Karlovy Vary', slug: 'karlovy-vary' },
		{ name: 'Jablonec nad Nisou', slug: 'jablonec-nad-nisou' },
		{ name: 'Prostějov', slug: 'prostejov' },
		{ name: 'Mladá Boleslav', slug: 'mlada-boleslav' },
		{ name: 'Přerov', slug: 'prerov' },
		{ name: 'Česká Lípa', slug: 'ceska-lipa' },
		{ name: 'Třebíč', slug: 'trebic' },
		{ name: 'Třinec', slug: 'trinec' },
		{ name: 'Tábor', slug: 'tabor' },
		{ name: 'Znojmo', slug: 'znojmo' },
		{ name: 'Kolín', slug: 'kolin' },
		{ name: 'Příbram', slug: 'pribram' },
		{ name: 'Cheb', slug: 'cheb' },
		{ name: 'Písek', slug: 'pisek' },
		{ name: 'Trutnov', slug: 'trutnov' },
		{ name: 'Orlová', slug: 'orlova' },
		{ name: 'Kroměříž', slug: 'kromeriz' },
		{ name: 'Vsetín', slug: 'vsetin' },
		{ name: 'Šumperk', slug: 'sumperk' },
		{ name: 'Uherské Hradiště', slug: 'uherske-hradiste' },
		{ name: 'Břeclav', slug: 'breclav' },
		{ name: 'Hodonín', slug: 'hodonin' },
		{ name: 'Český Těšín', slug: 'cesky-tesin' },
		{ name: 'Litoměřice', slug: 'litomerice' },
		{ name: 'Havlíčkův Brod', slug: 'havlickuv-brod' },
		{ name: 'Nový Jičín', slug: 'novy-jicin' },
		{ name: 'Chrudim', slug: 'chrudim' },
		{ name: 'Krnov', slug: 'krnov' },
		{ name: 'Litvínov', slug: 'litvinov' },
		{ name: 'Strakonice', slug: 'strakonice' },
		{ name: 'Sokolov', slug: 'sokolov' },
		{ name: 'Valašské Meziříčí', slug: 'valasske-mezirici' },
		{ name: 'Klatovy', slug: 'klatovy' },
		{ name: 'Kopřivnice', slug: 'koprivnice' },
		{ name: 'Jindřichův Hradec', slug: 'jindrichuv-hradec' },
		{ name: 'Kutná Hora', slug: 'kutna-hora' },
		{ name: 'Bohumín', slug: 'bohumin' },
		{ name: 'Žďár nad Sázavou', slug: 'zdar-nad-sazavou' },
		{ name: 'Vyškov', slug: 'vyskov' },
		{ name: 'Beroun', slug: 'beroun' },
		{ name: 'Blansko', slug: 'blansko' },
		{ name: 'Mělník', slug: 'melnik' },
		{ name: 'Náchod', slug: 'nachod' },
		{ name: 'Jirkov', slug: 'jirkov' },
		{ name: 'Brandýs nad Labem', slug: 'brandys-nad-labem' },
		{ name: 'Žatec', slug: 'zatec' },
		{ name: 'Kralupy nad Vltavou', slug: 'kralupy-nad-vltavou' },
		{ name: 'Louny', slug: 'louny' },
		{ name: 'Kadaň', slug: 'kadan' },
		{ name: 'Hranice', slug: 'hranice' },
		{ name: 'Otrokovice', slug: 'otrokovice' },
		{ name: 'Benešov', slug: 'benesov' },
		{ name: 'Svitavy', slug: 'svitavy' },
		{ name: 'Uherský Brod', slug: 'uhersky-brod' },
		{ name: 'Říčany', slug: 'ricany' },
		{ name: 'Rožnov pod Radhoštěm', slug: 'roznov-pod-radhostem' },
		{ name: 'Jičín', slug: 'jicin' },
		{ name: 'Slaný', slug: 'slany' },
		{ name: 'Neratovice', slug: 'neratovice' },
		{ name: 'Ostrov', slug: 'ostrov' },
		{ name: 'Pelhřimov', slug: 'pelhrimov' },
		{ name: 'Bruntál', slug: 'bruntal' },
		{ name: 'Dvůr Králové nad Labem', slug: 'dvur-kralove-nad-labem' },
		{ name: 'Rakovník', slug: 'rakovnik' },
		{ name: 'Česká Třebová', slug: 'ceska-trebova' },
		{ name: 'Nymburk', slug: 'nymburk' },
		{ name: 'Varnsdorf', slug: 'varnsdorf' },
		{ name: 'Bílina', slug: 'bilina' },
		{ name: 'Poděbrady', slug: 'podebrady' },
		{ name: 'Klášterec nad Ohří', slug: 'klasterec-nad-ohri' },
		{ name: 'Turnov', slug: 'turnov' },
		{ name: 'Ústí nad Orlicí', slug: 'usti-nad-orlici' },
		{ name: 'Rokycany', slug: 'rokycany' },
		{ name: 'Hlučín', slug: 'hlucin' },
		{ name: 'Zábřeh', slug: 'zabreh' },
		{ name: 'Šternberk', slug: 'sternberk' },
		{ name: 'Chodov', slug: 'chodov' },
		{ name: 'Tachov', slug: 'tachov' },
		{ name: 'Roudnice nad Labem', slug: 'roudnice-nad-labem' },
		{ name: 'Aš', slug: 'as' },
		{ name: 'Český Krumlov', slug: 'cesky-krumlov' },
		{ name: 'Milovice', slug: 'milovice' },
		{ name: 'Krupka', slug: 'krupka' },
		{ name: 'Jaroměř', slug: 'jaromer' },
		{ name: 'Mariánské Lázně', slug: 'marianske-lazne' },
		{ name: 'Čelákovice', slug: 'celakovice' },
		{ name: 'Vysoké Mýto', slug: 'vysoke-myto' },
		{ name: 'Vrchlabí', slug: 'vrchlabi' },
		{ name: 'Boskovice', slug: 'boskovice' },
		{ name: 'Nový Bor', slug: 'novy-bor' },
		{ name: 'Holešov', slug: 'holesov' },
		{ name: 'Vlašim', slug: 'vlasim' },
		{ name: 'Velké Meziříčí', slug: 'velke-mezirici' },
		{ name: 'Uničov', slug: 'unicov' },
		{ name: 'Kyjov', slug: 'kyjov' },
		{ name: 'Kuřim', slug: 'kurim' },
		{ name: 'Domažlice', slug: 'domazlice' },
		{ name: 'Humpolec', slug: 'humpolec' },
		{ name: 'Rychnov nad Kněžnou', slug: 'rychnov-nad-kneznou' },
		{ name: 'Rumburk', slug: 'rumburk' },
		{ name: 'Sušice', slug: 'susice' },
		{ name: 'Jeseník', slug: 'jesenik' },
		{ name: 'Prachatice', slug: 'prachatice' },
		{ name: 'Veselí nad Moravou', slug: 'veseli-nad-moravou' },
		{ name: 'Frenštát pod Radhoštěm', slug: 'frenstat-pod-radhostem' },
		{ name: 'Čáslav', slug: 'caslav' },
		{ name: 'Králův Dvůr', slug: 'kraluv-dvur' },
		{ name: 'Litomyšl', slug: 'litomysl' },
		{ name: 'Nové Město na Moravě', slug: 'nove-mesto-na-morave' },
		{ name: 'Frýdlant nad Ostravicí', slug: 'frydlant-nad-ostravici' },
		{ name: 'Jesenice', slug: 'jesenice' },
		{ name: 'Ivančice', slug: 'ivancice' },
		{ name: 'Lysá nad Labem', slug: 'lysa-nad-labem' },
		{ name: 'Moravská Třebová', slug: 'moravska-trebova' },
		{ name: 'Litovel', slug: 'litovel' },
		{ name: 'Hlinsko', slug: 'hlinsko' },
		{ name: 'Lanškroun', slug: 'lanskroun' },
		{ name: 'Přelouč', slug: 'prelouc' },
		{ name: 'Studénka', slug: 'studenka' },
		{ name: 'Tišnov', slug: 'tisnov' },
		{ name: 'Nové Město nad Metují', slug: 'nove-mesto-nad-metuji' },
		{ name: 'Mohelnice', slug: 'mohelnice' },
		{ name: 'Chotěboř', slug: 'chotebor' },
		{ name: 'Nová Paka', slug: 'nova-paka' },
		{ name: 'Hostivice', slug: 'hostivice' },
		{ name: 'Mnichovo Hradiště', slug: 'mnichovo-hradiste' },
		{ name: 'Polička', slug: 'policka' },
		{ name: 'Dobříš', slug: 'dobris' },
		{ name: 'Roztoky', slug: 'roztoky' }
	],
	WOLTAIR_PL: [
		{ name: 'Warszawa', slug: 'warszawa', value: 'Warszawa' },
		{ name: 'Kraków', slug: 'krakow', value: 'Kraków' },
		{ name: 'Łódź', slug: 'lodz', value: 'Łódź' },
		{ name: 'Wrocław', slug: 'wroclaw', value: 'Wrocław' },
		{ name: 'Poznań', slug: 'poznan', value: 'Poznań' },
		{ name: 'Gdańsk', slug: 'gdansk', value: 'Gdańsk' },
		{ name: 'Szczecin', slug: 'szczecin', value: 'Szczecin' },
		{ name: 'Bydgoszcz', slug: 'bydgoszcz', value: 'Bydgoszcz' },
		{ name: 'Lublin', slug: 'lublin', value: 'Lublin' },
		{ name: 'Białystok', slug: 'bialystok', value: 'Białystok' },
		{ name: 'Katowice', slug: 'katowice', value: 'Katowice' },
		{ name: 'Gdynia', slug: 'gdynia', value: 'Gdynia' },
		{ name: 'Częstochowa', slug: 'czestochowa', value: 'Częstochowa' },
		{ name: 'Radom', slug: 'radom', value: 'Radom' },
		{ name: 'Toruń', slug: 'torun', value: 'Toruń' },
		{ name: 'Sosnowiec', slug: 'sosnowiec', value: 'Sosnowiec' },
		{ name: 'Rzeszów', slug: 'rzeszow', value: 'Rzeszów' },
		{ name: 'Kielce', slug: 'kielce', value: 'Kielce' },
		{ name: 'Gliwice', slug: 'gliwice', value: 'Gliwice' },
		{ name: 'Olsztyn', slug: 'olsztyn', value: 'Olsztyn' },
		{ name: 'Zabrze', slug: 'zabrze', value: 'Zabrze' },
		{ name: 'Bielsko-Biała', slug: 'bielsko-biala', value: 'Bielsko-Biała' },
		{ name: 'Bytom', slug: 'bytom', value: 'Bytom' },
		{ name: 'Zielona Góra', slug: 'zielona-gora', value: 'Zielona Góra' },
		{ name: 'Rybnik', slug: 'rybnik', value: 'Rybnik' },
		{ name: 'Ruda Śląska', slug: 'ruda-slaska', value: 'Ruda Śląska' },
		{ name: 'Opole', slug: 'opole', value: 'Opole' },
		{ name: 'Tychy', slug: 'tychy', value: 'Tychy' },
		{ name: 'Gorzów Wielkopolski', slug: 'gorzow-wielkopolski', value: 'Gorzów Wielkopolski' },
		{ name: 'Elbląg', slug: 'elblag', value: 'Elbląg' },
		{ name: 'Dąbrowa Górnicza', slug: 'dabrowa-gornicza', value: 'Dąbrowa Górnicza' },
		{ name: 'Płock', slug: 'plock', value: 'Płock' },
		{ name: 'Wałbrzych', slug: 'walbrzych', value: 'Wałbrzych' },
		{ name: 'Włocławek', slug: 'wloclawek', value: 'Włocławek' },
		{ name: 'Tarnów', slug: 'tarnow', value: 'Tarnów' },
		{ name: 'Chorzów', slug: 'chorzow', value: 'Chorzów' },
		{ name: 'Koszalin', slug: 'koszalin', value: 'Koszalin' },
		{ name: 'Kalisz', slug: 'kalisz', value: 'Kalisz' },
		{ name: 'Legnica', slug: 'legnica', value: 'Legnica' },
		{ name: 'Grudziądz', slug: 'grudziadz', value: 'Grudziądz' },
		{ name: 'Jaworzno', slug: 'jaworzno', value: 'Jaworzno' },
		{ name: 'Słupsk', slug: 'slupsk', value: 'Słupsk' },
		{ name: 'Jastrzębie-Zdrój', slug: 'jastrzebie-zdroj', value: 'Jastrzębie-Zdrój' },
		{ name: 'Nowy Sącz', slug: 'nowy-sacz', value: 'Nowy Sącz' },
		{ name: 'Jelenia Góra', slug: 'jelenia-gora', value: 'Jelenia Góra' },
		{ name: 'Siedlce', slug: 'siedlce', value: 'Siedlce' },
		{ name: 'Mysłowice', slug: 'myslowice', value: 'Mysłowice' },
		{ name: 'Konin', slug: 'konin', value: 'Konin' },
		{ name: 'Piła', slug: 'pila', value: 'Piła' },
		{ name: 'Piotrków Trybunalski', slug: 'piotrkow-trybunalski', value: 'Piotrków Trybunalski' },
		{ name: 'Lubin', slug: 'lubin', value: 'Lubin' },
		{ name: 'Inowrocław', slug: 'inowroclaw', value: 'Inowrocław' },
		{ name: 'Ostrów Wielkopolski', slug: 'ostrow-wielkopolski', value: 'Ostrów Wielkopolski' },
		{ name: 'Suwałki', slug: 'suwalki', value: 'Suwałki' },
		{ name: 'Stargard', slug: 'stargard', value: 'Stargard' },
		{ name: 'Gniezno', slug: 'gniezno', value: 'Gniezno' },
		{
			name: 'Ostrowiec Świętokrzyski',
			slug: 'ostrowiec-swietokrzyski',
			value: 'Ostrowiec Świętokrzyski'
		},
		{ name: 'Siemianowice Śląskie', slug: 'siemianowice-slaskie', value: 'Siemianowice Śląskie' },
		{ name: 'Głogów', slug: 'glogow', value: 'Głogów' },
		{ name: 'Pabianice', slug: 'pabianice', value: 'Pabianice' },
		{ name: 'Leszno', slug: 'leszno', value: 'Leszno' },
		{ name: 'Żory', slug: 'zory', value: 'Żory' },
		{ name: 'Zamość', slug: 'zamosc', value: 'Zamość' },
		{ name: 'Pruszków', slug: 'pruszkow', value: 'Pruszków' },
		{ name: 'Łomża', slug: 'lomza', value: 'Łomża' },
		{ name: 'Ełk', slug: 'elk', value: 'Ełk' },
		{ name: 'Tarnowskie Góry', slug: 'tarnowskie-gory', value: 'Tarnowskie Góry' },
		{ name: 'Tomaszów Mazowiecki', slug: 'tomaszow-mazowiecki', value: 'Tomaszów Mazowiecki' },
		{ name: 'Chełm', slug: 'chelm', value: 'Chełm' },
		{ name: 'Mielec', slug: 'mielec', value: 'Mielec' },
		{ name: 'Kędzierzyn-Koźle', slug: 'kedzierzyn-kozle', value: 'Kędzierzyn-Koźle' },
		{ name: 'Przemyśl', slug: 'przemysl', value: 'Przemyśl' },
		{ name: 'Stalowa Wola', slug: 'stalowa-wola', value: 'Stalowa Wola' },
		{ name: 'Tczew', slug: 'tczew', value: 'Tczew' },
		{ name: 'Biała Podlaska', slug: 'biala-podlaska', value: 'Biała Podlaska' },
		{ name: 'Bełchatów', slug: 'belchatow', value: 'Bełchatów' },
		{ name: 'Świdnica', slug: 'swidnica', value: 'Świdnica' },
		{ name: 'Będzin', slug: 'bedzin', value: 'Będzin' },
		{ name: 'Zgierz', slug: 'zgierz', value: 'Zgierz' },
		{ name: 'Piekary Śląskie', slug: 'piekary-slaskie', value: 'Piekary Śląskie' },
		{ name: 'Racibórz', slug: 'raciborz', value: 'Racibórz' },
		{ name: 'Legionowo', slug: 'legionowo', value: 'Legionowo' },
		{ name: 'Ostrołęka', slug: 'ostroleka', value: 'Ostrołęka' },
		{ name: 'Rumia', slug: 'rumia', value: 'Rumia' },
		{ name: 'Świętochłowice', slug: 'swietochlowice', value: 'Świętochłowice' },
		{ name: 'Wejherowo', slug: 'wejherowo', value: 'Wejherowo' },
		{ name: 'Zawiercie', slug: 'zawiercie', value: 'Zawiercie' },
		{ name: 'Piaseczno', slug: 'piaseczno', value: 'Piaseczno' },
		{ name: 'Skierniewice', slug: 'skierniewice', value: 'Skierniewice' },
		{ name: 'Starachowice', slug: 'starachowice', value: 'Starachowice' },
		{ name: 'Wodzisław Śląski', slug: 'wodzislaw-slaski', value: 'Wodzisław Śląski' },
		{ name: 'Starogard Gdański', slug: 'starogard-gdanski', value: 'Starogard Gdański' },
		{ name: 'Puławy', slug: 'pulawy', value: 'Puławy' },
		{ name: 'Tarnobrzeg', slug: 'tarnobrzeg', value: 'Tarnobrzeg' },
		{ name: 'Kołobrzeg', slug: 'kolobrzeg', value: 'Kołobrzeg' },
		{ name: 'Krosno', slug: 'krosno', value: 'Krosno' },
		{ name: 'Radomsko', slug: 'radomsko', value: 'Radomsko' },
		{ name: 'Dębica', slug: 'debica', value: 'Dębica' },
		{ name: 'Otwock', slug: 'otwock', value: 'Otwock' },
		{ name: 'Skarżysko-Kamienna', slug: 'skarzysko-kamienna', value: 'Skarżysko-Kamienna' },
		{ name: 'Nysa', slug: 'nysa', value: 'Nysa' },
		{ name: 'Kutno', slug: 'kutno', value: 'Kutno' },
		{ name: 'Sieradz', slug: 'sieradz', value: 'Sieradz' },
		{ name: 'Zduńska Wola', slug: 'zdunska-wola', value: 'Zduńska Wola' },
		{ name: 'Mikołów', slug: 'mikolow', value: 'Mikołów' },
		{ name: 'Świnoujście', slug: 'swinoujscie', value: 'Świnoujście' },
		{ name: 'Mińsk Mazowiecki', slug: 'minsk-mazowiecki', value: 'Mińsk Mazowiecki' },
		{ name: 'Szczecinek', slug: 'szczecinek', value: 'Szczecinek' },
		{ name: 'Chojnice', slug: 'chojnice', value: 'Chojnice' },
		{ name: 'Żyrardów', slug: 'zyrardow', value: 'Żyrardów' },
		{ name: 'Świdnik', slug: 'swidnik', value: 'Świdnik' },
		{ name: 'Ząbki', slug: 'zabki', value: 'Ząbki' },
		{ name: 'Bolesławiec', slug: 'boleslawiec', value: 'Bolesławiec' },
		{ name: 'Kwidzyn', slug: 'kwidzyn', value: 'Kwidzyn' },
		{ name: 'Nowa Sól', slug: 'nowa-sol', value: 'Nowa Sól' },
		{ name: 'Malbork', slug: 'malbork', value: 'Malbork' },
		{ name: 'Knurów', slug: 'knurow', value: 'Knurów' },
		{ name: 'Oświęcim', slug: 'oswiecim', value: 'Oświęcim' },
		{ name: 'Jarosław', slug: 'jaroslaw', value: 'Jarosław' },
		{ name: 'Żary', slug: 'zary', value: 'Żary' },
		{ name: 'Sanok', slug: 'sanok', value: 'Sanok' },
		{ name: 'Oleśnica', slug: 'olesnica', value: 'Oleśnica' },
		{ name: 'Wołomin', slug: 'wolomin', value: 'Wołomin' },
		{ name: 'Marki', slug: 'marki', value: 'Marki' },
		{ name: 'Chrzanów', slug: 'chrzanow', value: 'Chrzanów' },
		{ name: 'Sochaczew', slug: 'sochaczew', value: 'Sochaczew' },
		{ name: 'Czechowice-Dziedzice', slug: 'czechowice-dziedzice', value: 'Czechowice-Dziedzice' },
		{ name: 'Sopot', slug: 'sopot', value: 'Sopot' },
		{ name: 'Brzeg', slug: 'brzeg', value: 'Brzeg' },
		{ name: 'Lębork', slug: 'lebork', value: 'Lębork' },
		{ name: 'Olkusz', slug: 'olkusz', value: 'Olkusz' },
		{ name: 'Jasło', slug: 'jaslo', value: 'Jasło' },
		{ name: 'Cieszyn', slug: 'cieszyn', value: 'Cieszyn' },
		{ name: 'Kraśnik', slug: 'krasnik', value: 'Kraśnik' },
		{ name: 'Nowy Targ', slug: 'nowy-targ', value: 'Nowy Targ' },
		{ name: 'Iława', slug: 'ilawa', value: 'Iława' },
		{ name: 'Oława', slug: 'olawa', value: 'Oława' },
		{ name: 'Dzierżoniów', slug: 'dzierzoniow', value: 'Dzierżoniów' },
		{ name: 'Ostróda', slug: 'ostroda', value: 'Ostróda' },
		{ name: 'Grodzisk Mazowiecki', slug: 'grodzisk-mazowiecki', value: 'Grodzisk Mazowiecki' },
		{ name: 'Police', slug: 'police', value: 'Police' },
		{ name: 'Luboń', slug: 'lubon', value: 'Luboń' },
		{ name: 'Pruszcz Gdański', slug: 'pruszcz-gdanski', value: 'Pruszcz Gdański' },
		{ name: 'Myszków', slug: 'myszkow', value: 'Myszków' },
		{ name: 'Mława', slug: 'mlawa', value: 'Mława' },
		{ name: 'Czeladź', slug: 'czeladz', value: 'Czeladź' },
		{ name: 'Września', slug: 'wrzesnia', value: 'Września' },
		{ name: 'Żywiec', slug: 'zywiec', value: 'Żywiec' },
		{ name: 'Augustów', slug: 'augustow', value: 'Augustów' },
		{ name: 'Swarzędz', slug: 'swarzedz', value: 'Swarzędz' },
		{ name: 'Zgorzelec', slug: 'zgorzelec', value: 'Zgorzelec' },
		{ name: 'Bochnia', slug: 'bochnia', value: 'Bochnia' },
		{ name: 'Bielawa', slug: 'bielawa', value: 'Bielawa' },
		{ name: 'Łuków', slug: 'lukow', value: 'Łuków' },
		{ name: 'Śrem', slug: 'srem', value: 'Śrem' },
		{ name: 'Giżycko', slug: 'gizycko', value: 'Giżycko' },
		{ name: 'Brodnica', slug: 'brodnica', value: 'Brodnica' },
		{ name: 'Krotoszyn', slug: 'krotoszyn', value: 'Krotoszyn' },
		{ name: 'Nowy Dwór Mazowiecki', slug: 'nowy-dwor-mazowiecki', value: 'Nowy Dwór Mazowiecki' },
		{ name: 'Łowicz', slug: 'lowicz', value: 'Łowicz' },
		{ name: 'Czerwionka-Leszczyny', slug: 'czerwionka-leszczyny', value: 'Czerwionka-Leszczyny' },
		{ name: 'Wyszków', slug: 'wyszkow', value: 'Wyszków' },
		{ name: 'Gorlice', slug: 'gorlice', value: 'Gorlice' },
		{ name: 'Zakopane', slug: 'zakopane', value: 'Zakopane' },
		{ name: 'Kętrzyn', slug: 'ketrzyn', value: 'Kętrzyn' },
		{ name: 'Reda', slug: 'reda', value: 'Reda' },
		{ name: 'Turek', slug: 'turek', value: 'Turek' },
		{ name: 'Kłodzko', slug: 'klodzko', value: 'Kłodzko' },
		{ name: 'Jarocin', slug: 'jarocin', value: 'Jarocin' },
		{ name: 'Biłgoraj', slug: 'bilgoraj', value: 'Biłgoraj' },
		{ name: 'Pszczyna', slug: 'pszczyna', value: 'Pszczyna' },
		{ name: 'Wągrowiec', slug: 'wagrowiec', value: 'Wągrowiec' },
		{ name: 'Świecie', slug: 'swiecie', value: 'Świecie' },
		{ name: 'Żagań', slug: 'zagan', value: 'Żagań' },
		{ name: 'Kobyłka', slug: 'kobylka', value: 'Kobyłka' },
		{ name: 'Bielsk Podlaski', slug: 'bielsk-podlaski', value: 'Bielsk Podlaski' },
		{ name: 'Wałcz', slug: 'walcz', value: 'Wałcz' },
		{ name: 'Skawina', slug: 'skawina', value: 'Skawina' },
		{ name: 'Wieliczka', slug: 'wieliczka', value: 'Wieliczka' },
		{ name: 'Białogard', slug: 'bialogard', value: 'Białogard' },
		{ name: 'Kościerzyna', slug: 'koscierzyna', value: 'Kościerzyna' },
		{ name: 'Kościan', slug: 'koscian', value: 'Kościan' },
		{ name: 'Środa Wielkopolska', slug: 'sroda-wielkopolska', value: 'Środa Wielkopolska' },
		{ name: 'Lubliniec', slug: 'lubliniec', value: 'Lubliniec' },
		{ name: 'Kluczbork', slug: 'kluczbork', value: 'Kluczbork' },
		{ name: 'Sandomierz', slug: 'sandomierz', value: 'Sandomierz' },
		{ name: 'Bartoszyce', slug: 'bartoszyce', value: 'Bartoszyce' },
		{ name: 'Szczytno', slug: 'szczytno', value: 'Szczytno' },
		{ name: 'Świebodzice', slug: 'swiebodzice', value: 'Świebodzice' },
		{ name: 'Piastów', slug: 'piastow', value: 'Piastów' },
		{ name: 'Jawor', slug: 'jawor', value: 'Jawor' },
		{ name: 'Polkowice', slug: 'polkowice', value: 'Polkowice' },
		{ name: 'Ostrów Mazowiecka', slug: 'ostrow-mazowiecka', value: 'Ostrów Mazowiecka' },
		{ name: 'Łaziska Górne', slug: 'laziska-gorne', value: 'Łaziska Górne' },
		{ name: 'Goleniów', slug: 'goleniow', value: 'Goleniów' },
		{ name: 'Płońsk', slug: 'plonsk', value: 'Płońsk' },
		{ name: 'Zambrów', slug: 'zambrow', value: 'Zambrów' },
		{ name: 'Wieluń', slug: 'wielun', value: 'Wieluń' },
		{ name: 'Aleksandrów Łódzki', slug: 'aleksandrow-lodzki', value: 'Aleksandrów Łódzki' }
	],
	WOLTAIR_DE: [
		{ name: 'Baden-Württemberg', slug: 'baden-wurttemberg', value: 'Baden-Württemberg' },
		{ name: 'Bayern', slug: 'bayern', value: 'Bayern' },
		{ name: 'Berlin', slug: 'berlin', value: 'Berlin' },
		{ name: 'Brandenburg', slug: 'brandenburg', value: 'Brandenburg' },
		{ name: 'Bremen', slug: 'bremen', value: 'Bremen' },
		{ name: 'Hamburg', slug: 'hamburg', value: 'Hamburg' },
		{ name: 'Hessen', slug: 'hessen', value: 'Hessen' },
		{
			name: 'Mecklenburg-Vorpommern',
			slug: 'mecklenburg-vorpommern',
			value: 'Mecklenburg-Vorpommern'
		},
		{ name: 'Niedersachsen', slug: 'niedersachsen', value: 'Niedersachsen' },
		{ name: 'Nordrhein-Westfalen', slug: 'nordrhein-westfalen', value: 'Nordrhein-Westfalen' },
		{ name: 'Rheinland-Pfalz', slug: 'rheinland-pfalz', value: 'Rheinland-Pfalz' },
		{ name: 'Saarland', slug: 'saarland', value: 'Saarland' },
		{ name: 'Sachsen', slug: 'sachsen', value: 'Sachsen' },
		{ name: 'Sachsen-Anhalt', slug: 'sachsen-anhalt', value: 'Sachsen-Anhalt' },
		{ name: 'Schleswig-Holstein', slug: 'schleswig-holstein', value: 'Schleswig-Holstein' },
		{ name: 'Thüringen', slug: 'thuringen', value: 'Thüringen' }
	],
	WOLTAIR_IT: [
		{ name: 'Abruzzo', slug: 'abruzzo', value: 'Abruzzo' },
		{ name: 'Basilicata', slug: 'basilicata', value: 'Basilicata' },
		{ name: 'Calabria', slug: 'calabria', value: 'Calabria' },
		{ name: 'Campania', slug: 'campania', value: 'Campania' },
		{ name: 'Emilia Romagna', slug: 'emilia-romagna', value: 'Emilia Romagna' },
		{
			name: 'Friuli Venezia Giulia',
			slug: 'friuli-venezia-giulia',
			value: 'Friuli Venezia Giulia'
		},
		{ name: 'Lazio', slug: 'lazio', value: 'Lazio' },
		{ name: 'Liguria', slug: 'liguria', value: 'Liguria' },
		{ name: 'Lombardia', slug: 'lombardia', value: 'Lombardia' },
		{ name: 'Marche', slug: 'marche', value: 'Marche' },
		{ name: 'Molise', slug: 'molise', value: 'Molise' },
		{ name: 'Piemonte', slug: 'piemonte', value: 'Piemonte' },
		{ name: 'Puglia', slug: 'puglia', value: 'Puglia' },
		{ name: 'Sardegna', slug: 'sardegna', value: 'Sardegna' },
		{ name: 'Sicilia', slug: 'sicilia', value: 'Sicilia' },
		{ name: 'Toscana', slug: 'toscana', value: 'Toscana' },
		{ name: 'Trentino-Alto Adige', slug: 'trentino-alto-adige', value: 'Trentino-Alto Adige' },
		{ name: 'Umbria', slug: 'umbria', value: 'Umbria' },
		{ name: "Valle d'Aosta", slug: 'valle-d-aosta', value: "Valle d'Aosta" },
		{ name: 'Veneto', slug: 'veneto', value: 'Veneto' }
	]
}

export const TODO_TYPE = {
	CUSTOMER_REQUEST: 'CUSTOMER_REQUEST'
}

export const ENDPOINTS = {
	LOCATION_CREATE: 'locationCreate'
}

export const INVOICE_TYPE = {
	SERVIS_SERVIS: 'SERVIS_SERVIS',
	SERVIS_REVIZE: 'SERVIS_REVIZE',
	ZAMERENI: 'ZAMERENI',
	DOBROPIS: 'DOBROPIS',
	DOBROPIS_ZAMERENI: 'DOBROPIS_ZAMERENI',
	DEPOSIT_HVAC: 'DEPOSIT_HVAC',
	DEPOSIT_HVAC_PRENESEN: 'DEPOSIT_HVAC_PRENESEN',
	DEPOSIT_FVE: 'DEPOSIT_FVE',
	DEPOSIT_FVE_PRENESEN: 'DEPOSIT_FVE_PRENESEN',
	GRANT: 'DEPOSIT_FVE_PRENESEN'
}

export const categories = [
	{
		key: 'TEPELNE_CERPADLO',
		value: 'Tepelné čerpadlo',
		text: 'tepelného čerpadla',
		url: 'tepelna-cerpadla',
		short: 'TČ',
		color: '#54478C'
	},
	{
		key: 'FOTOVOLTAIKA',
		value: 'Fotovoltaika',
		text: 'fotovoltaiky',
		url: 'fotovoltaika',
		short: 'FVE',
		color: '#F1C453'
	},
	{
		key: 'PLYNOVY_KOTEL',
		value: 'Plynový kotel',
		text: 'plynového kotle',
		url: 'plynove-kotle',
		short: 'PK',
		color: '#F29E4C'
	},
	{
		key: 'ELEKTROKOTEL',
		value: 'Elektrický kotel',
		text: 'elektrického kotle',
		short: 'EK',
		color: '#EFEA5A'
	},
	{
		key: 'KLIMATIZACE',
		value: 'Klimatizace',
		text: 'klimatizace',
		short: 'KL',
		color: '#2C699A'
	},
	{
		key: 'OTOPNA_SOUSTAVA',
		value: 'Otopná soustava',
		text: 'otopné soustavy',
		short: 'OS',
		color: '#048BA8'
	},
	{
		key: 'REKUPERACE',
		value: 'Rekuperace',
		text: 'rekuperace',
		short: 'RE',
		color: '#0DB39E'
	},
	{
		key: 'SOLARNI_SYSTEM',
		value: 'Solární systém',
		text: 'solárního systému',
		short: 'SS',
		color: '#16DB93'
	},
	{
		key: 'PODLAHOVE_TOPENI',
		value: 'Podlahové topení',
		text: 'podlahového topení',
		short: 'PDL',
		color: '#83E377'
	},
	{
		key: 'ROZVODY_VODY_A_KANALIZACE',
		value: 'Rozvody a kanalizace',
		text: 'rozvodů a kanalizace',
		short: 'RK',
		color: '#B9E769'
	},
	{
		key: 'ZASOBNIK_TEPLE_VODY',
		value: 'Zásobník teplé vody',
		text: 'zásobníku teplé vody',
		short: 'ZTV',
		color: '#F1C453'
	}
]

export const CATEGORY_TEXT = (key: string) => {
	const result = categories.find((c) => c.key === key)
	if (result) return result.text
	return key
}

export const CATEGORY_TEXT_FROM_URL = (url: string) => {
	const result = categories.find((c) => c.url === url)
	if (result) return result.text
	return url
}

export const CATEGORY_URL = (key: string) => {
	const result = categories.find((c) => c.key === key)
	if (result) return result.url
	return key
}

export const CATEGORY_KEY = (url: string) => {
	const result = categories.find((c) => c.url === url)
	if (result) return result.key
	return null
}

export const orderTypes = [
	{ text: 'Instalační zakázky', short: 'Instalace', value: 'INSTALACE' },
	{ text: 'Servisní zakázky - kontrola', short: 'Kontrola', value: 'REVIZE' },
	{ text: 'Servisní zakázky - servis', short: 'Servis', value: 'SERVIS' },
	{ text: 'Zprovoznění', short: 'Zprovoznění', value: 'ZPROVOZNENI' },
	{ text: 'Jiná', short: 'Jiná', value: 'OTHER' }
]

export const existingHeatSource = [
	{
		answer: 'Akumulační kamna',
		value: 'storageHeater'
	},
	{
		answer: 'Elektrokotel',
		value: 'electricBoiler'
	},
	{
		answer: 'Elektropatronu v nádrži (akumulace)',
		value: 'electropatronInTankAccumulation'
	},
	{
		answer: 'Horkovod CZT (centrální zásobování teplem)',
		value: 'HotWaterPipelineCZT'
	},
	{
		answer: 'Kotel na dřevo',
		value: 'woodBoiler'
	},
	{
		answer: 'Kotel na LTO (lehký topný olej)',
		value: 'boilerOnLightFuelOils'
	},
	{
		answer: 'Kotel na pelety',
		value: 'pelletBoiler'
	},
	{
		answer: 'Kotel na uhlí',
		value: 'coalBoiler'
	},
	{
		answer: 'Krbová kamna',
		value: 'stoves'
	},
	{
		answer: 'Plynový atmosférický kotel',
		value: 'gasAtmosphericBoiler'
	},
	{
		answer: 'Plynový kondenzační kotel',
		value: 'gasCondensingBoiler'
	},
	{
		answer: 'Plynový kotel na propan',
		value: 'propaneGasBoiler'
	},
	{
		answer: 'Plynový kotel',
		value: 'GasBoiler'
	},
	{
		answer: 'Plynový kotel turbo',
		value: 'GasTurboBoiler'
	},
	{
		answer: 'Přímotopné tělesa',
		value: 'directHeatingElements',
		color: 'red'
	},
	{
		answer: 'Tepelné čerpadlo',
		value: 'heatPump'
	},
	{
		answer: 'Wafky',
		value: 'wafky',
		color: 'red'
	},
	{
		answer: 'Zplynovací kotel na dřevo',
		value: 'GasificationBoilerForWood'
	},
	{
		answer: 'Žádný',
		value: 'nothing'
	},
	{
		answer: 'Jiný',
		value: 'other'
	}
]

export const HEAT_SOURCE_ANSWER = (key: string) => {
	const result = existingHeatSource.find((c) => c.value === key)
	return result?.answer || key
}

export const houseBuiltOrApproved = [
	{
		answer: 'Dům ještě není postaven',
		value: 'houseIsNotBuildYet'
	},
	{
		answer: 'Před rokem 1945',
		value: 'before1945'
	},
	{
		answer: 'Mezi lety 1946 - 1970',
		value: '1946-1970'
	},
	{
		answer: 'Mezi lety 1971 - 1989',
		value: '1971-1989'
	},
	{
		answer: 'Po roce 1999',
		value: 'after1990'
	},
	{
		answer: 'Mezi lety 1990 - 2005',
		value: '19990-2005'
	},
	{
		answer: 'Po roce 2006',
		value: 'after2006'
	},
	{
		answer: 'Novostavba',
		value: 'newBuilding'
	},
	{
		answer: 'Starší stavba, nově izolovaná',
		value: 'oldBuildingInsulated'
	},
	{
		answer: 'Rekostrukce',
		value: 'reconstruction'
	}
]

export const HOUSE_BUILT = (key: string) => {
	const result = houseBuiltOrApproved.find((c) => c.value === key)
	return result?.answer || key
}

export const ORDER_TYPE_SHORT = (value: string) => {
	const result = orderTypes.find((c) => c.value === value)
	if (result) return result.short
	return value
}

const heatSourceType = [
	{
		name: 'Tepelné čerpadlo',
		slug: 'tepelna-cerpadla'
	},
	{
		name: 'Fotovoltaické panely',
		slug: 'fotovoltaika'
	}
]

export function HEAT_SOURCE_TYPE_NAME(slug: string) {
	const result = heatSourceType.find((c) => c.slug === slug)
	return result?.name || slug
}

export const JOB_LOCATION = {
	woltairHQ: 'web.job_offer.location.woltairHQ',
	woltairIT: 'web.job_offer.location.woltairIT',
	wholeCountry: 'web.job_offer.location.wholeCountry'
}

export const JOB_CONTRACT_TYPE = {
	fullTimeEmployee: 'web.job_offer.contractType.fullTimeEmployee',
	partTimeEmployee: 'web.job_offer.contractType.partTimeEmployee',
	contract: 'web.job_offer.contractType.contract'
}

export const JOB_WORK_SCHEDULE = {
	fullTime: 'web.job_offer.jobWorkSchedule.fullTime',
	partTime: 'web.job_offer.jobWorkSchedule.partTime'
}

/// BAKED VARIABLES
//warranty periods
const HEAT_PUMP_WARRANTY_PERIOD_INIT = {
	WOLTAIR_CZ: {
		Vaillant: 5,
		Dalkin: 3,
		'Alpha Innotec': 3,
		WLTR: 5,
		default: 2
	},
	WOLTAIR_PL: {
		default: 5
	},
	WOLTAIR_DE: {
		default: 2
	},
	WOLTAIR_IT: {
		default: 2
	}
}
export const HEAT_PUMP_WARRANTY_PERIOD =
	HEAT_PUMP_WARRANTY_PERIOD_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]
//Country codes
const COUNTRY_CODE_INIT = {
	WOLTAIR_CZ: 'CZ',
	WOLTAIR_DE: 'DE',
	WOLTAIR_PL: 'PL',
	WOLTAIR_IT: 'IT'
}
export const COUNTRY_CODE = COUNTRY_CODE_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

const COUNTRY_CODE_A3_INIT = {
	WOLTAIR_CZ: 'CZE',
	WOLTAIR_DE: 'DEU',
	WOLTAIR_PL: 'POL',
	WOLTAIR_IT: 'ITA'
}
export const COUNTRY_CODE_A3 = COUNTRY_CODE_A3_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

// Currency settings
const COUNTRY_CURRENCY_INIT = {
	WOLTAIR_CZ: 'CZK',
	WOLTAIR_DE: 'EUR',
	WOLTAIR_IT: 'EUR',
	WOLTAIR_PL: 'PLN'
}
export const COUNTRY_CURRENCY = COUNTRY_CURRENCY_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

// Woltadvisor - HVAC preffered brand settings
const WA_HVAC_PREFFERED_BRAND_INIT = {
	WOLTAIR_CZ: {
		brand: false,
		highlight: true
	},
	WOLTAIR_PL: {
		brand: false,
		highlight: true
	},
	WOLTAIR_DE: {
		brand: false,
		highlight: true
	},
	WOLTAIR_IT: {
		brand: 'WLTR',
		highlight: false
	}
}

export const WA_HVAC_PREFFERED_BRAND =
	WA_HVAC_PREFFERED_BRAND_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

//Pagination settings
const POSTS_PER_PAGE_INIT = {
	BLOG: {
		WOLTAIR_CZ: 12,
		WOLTAIR_DE: 12,
		WOLTAIR_IT: 12,
		WOLTAIR_PL: 12
	},
	ADVICE: {
		WOLTAIR_CZ: 8,
		WOLTAIR_DE: 8,
		WOLTAIR_IT: 8,
		WOLTAIR_PL: 8
	},
	WOLTAIR_CZ: 12,
	WOLTAIR_DE: 12,
	WOLTAIR_IT: 12,
	WOLTAIR_PL: 12,
	DEFAULT: 12
}
export const POSTS_PER_PAGE = POSTS_PER_PAGE_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]
export const POSTS_PER_PAGE_BLOG =
	POSTS_PER_PAGE_INIT.BLOG[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales] ||
	POSTS_PER_PAGE_INIT['DEFAULT']
export const POSTS_PER_PAGE_ADVICE =
	POSTS_PER_PAGE_INIT.ADVICE[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales] ||
	POSTS_PER_PAGE_INIT['DEFAULT']

//Order of lead offers - used tabs on FVE offer
const FVE_VARIANT_ORDER_INIT = {
	WOLTAIR_CZ: {
		OPTIMUM: 1,
		SELF_SUFFICIENCY: 2
	},
	WOLTAIR_DE: {
		OPTIMUM: 1,
		SELF_SUFFICIENCY: 2
	},
	WOLTAIR_IT: {
		OPTIMUM: 1,
		SELF_SUFFICIENCY: 2
	},
	WOLTAIR_PL: {
		OPTIMUM: 2,
		SELF_SUFFICIENCY: 1
	}
}
export const FVE_VARIANT_ORDER = FVE_VARIANT_ORDER_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]
type Environment = 'local' | 'dev' | 'staging' | 'production'

//web urls settings
export const WEB_URLS = {
	WOLTAIR_CZ: {
		local: 'http://localhost:3000',
		dev: 'https://cz.dev.woltair.dev',
		staging: 'https://cz.staging.woltair.dev',
		production: 'https://www.woltair.cz'
	},
	WOLTAIR_PL: {
		local: 'http://localhost:3000',
		dev: 'https://pl.dev.woltair.dev',
		staging: 'https://pl.staging.woltair.dev',
		production: 'https://woltair-pl-web.pages.dev/'
	},
	WOLTAIR_DE: {
		local: 'http://localhost:3000',
		dev: 'https://de.dev.woltair.dev',
		staging: 'https://de.staging.woltair.dev',
		production: 'https://www.woltair.de'
	},
	WOLTAIR_IT: {
		local: 'http://localhost:3000',
		dev: 'https://it.dev.woltair.dev',
		staging: 'https://it.staging.woltair.dev',
		production: 'https://www.woltair.it'
	}
}

export const WEB_URL =
	WEB_URLS[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales][PUBLIC_ENVIRONMENT as Environment]

export const WEB_TRACKING_ID =
	PUBLIC_ENVIRONMENT === 'production'
		? {
				WOLTAIR_CZ: 'GTM-P7P837BX',
				WOLTAIR_PL: 'GTM-TM7BQMFB',
				WOLTAIR_DE: 'GTM-52KC5RRT',
				WOLTAIR_IT: 'GTM-NDNSTSBT'
		  }
		: {
				WOLTAIR_CZ: '',
				WOLTAIR_PL: '',
				WOLTAIR_DE: '',
				WOLTAIR_IT: ''
		  }

export const FACEBOOK_DOMAIN_VERIFICATION = {
	WOLTAIR_CZ: '',
	WOLTAIR_PL: '',
	WOLTAIR_DE: 'l8cnebylmmbxedfreoq66cje1og8mp',
	WOLTAIR_IT: '4j04mvaffkta9pjjxr943zzhektke2'
}
//
export const URLPART_TYPE: { [key: string]: string } = {
	hvac: HEAT_PUMP,
	pv: PHOTOVOLTAICS
}
export const URLPART_TYPE_LISTING: { [key: string]: string } = {
	hvac: HEAT_PUMP + '-listing',
	pv: PHOTOVOLTAICS + '-listing'
}
export const URLPART_ISO_INIT = {
	WOLTAIR_CZ: {
		NO: 'bez-zatepleni',
		LOW: 'nizke-zatepleni',
		MEDIUM: 'stredni-zatepleni',
		HIGH: 'vysoke-zatepleni'
	},
	WOLTAIR_DE: {
		NO: 'ohne-daemmung',
		LOW: 'geringe-daemmung',
		MEDIUM: 'mittlere-daemmung',
		HIGH: 'hohe-daemmung'
	},
	WOLTAIR_PL: {
		NO: 'bez-izolacji',
		LOW: 'niska-izolacja',
		MEDIUM: 'srednia-izolacja',
		HIGH: 'wysoka-izolacja'
	},
	WOLTAIR_IT: {
		NO: 'senza-isolamento',
		LOW: 'isolamento-basso',
		MEDIUM: 'isolamento-medio',
		HIGH: 'alto-isolamento'
	}
}

export const URLPART_ISO = URLPART_ISO_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const URLPART_HOUSEHEATTYPE_INIT = {
	WOLTAIR_CZ: {
		COAL_I_II: 'kotel-1-2',
		COAL: 'kotel-uhli',
		GAS: 'plynovy-kotel',
		GAS_OLD: 'plynovy-kotel-stary',
		GAS_CONDENSING: 'kondenzacni-kotel',
		ELECTRO: 'elektricky-kotel',
		OIL: 'olej',
		HEATPUMP: 'tepelne-cerpdlo'
	},
	WOLTAIR_DE: {
		COAL_I_II: 'kessel-1-2',
		COAL: 'kohlekessel',
		GAS: 'gaskessel',
		GAS_CONDENSING: 'kondensationskessel',
		ELECTRO: 'elektrokessel',
		OIL: 'oelheizung',
		HEATPUMP: 'waermepumpe'
	},
	WOLTAIR_PL: {
		COAL_I_II: 'kocioł-1-2',
		COAL: 'kocioł na węgiel',
		GAS: 'kocioł gazowy',
		GAS_CONDENSING: 'kocioł kondensacyjny',
		ELECTRO: 'kocioł elektryczny',
		OIL: 'ogrzewanie olejem',
		HEATPUMP: 'pompa ciepła'
	},
	WOLTAIR_IT: {
		COAL_I_II: 'caldaia-1-2',
		COAL: 'caldaia-a-carbone',
		GAS: 'caldaia-a-gas',
		GAS_CONDENSING: 'caldaia-a-condensazione',
		ELECTRO: 'caldaia-elettrica',
		OIL: 'riscaldamento-a-olio',
		HEATPUMP: 'pompa-di-calore'
	}
}
export const URLPART_HOUSEHEATTYPE =
	URLPART_HOUSEHEATTYPE_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const URLPART_WATERTEMP_INIT = {
	WOLTAIR_CZ: {
		true: 'vytapeni-podlahy',
		false: 'vytapeni-radiatorem'
	},
	WOLTAIR_DE: {
		true: 'fussbodenheizung',
		false: 'heizkoerperheizung'
	},
	WOLTAIR_PL: {
		true: 'ogrzewanie-podlogowe',
		false: 'ogrzewanie-grzejnikowe'
	},
	WOLTAIR_IT: {
		true: 'riscaldamento-a-pavimento',
		false: 'riscaldamento-con-radiatori'
	}
}

export const URLPART_WATERTEMP = URLPART_WATERTEMP_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const HVAC_BRANDS_INIT = {
	WOLTAIR_CZ: {
		wltr: {
			name: 'WLTR',
			slug: 'wltr'
		},
		bosch: {
			name: 'Bosch',
			slug: 'bosch'
		},
		nibe: {
			name: 'Nibe',
			slug: 'nibe'
		},
		stiebel: {
			name: 'Stiebel',
			slug: 'stiebel'
		},
		'alpha-innotec': {
			name: 'Alpha Innotec',
			slug: 'alpha-innotec'
		},
		buderus: {
			name: 'Buderus',
			slug: 'buderus'
		},
		daikin: {
			name: 'Daikin',
			slug: 'daikin'
		},
		'de-dietrich': {
			name: 'De Dietrich',
			slug: 'de-dietrich'
		},
		grundig: {
			name: 'Grundig',
			slug: 'grundig'
		},
		'hermann-saunier-duval': {
			name: 'Hermann Saunier Duval',
			slug: 'hermann-saunier-duval'
		},
		kronoterm: {
			name: 'Kronoterm',
			slug: 'kronoterm'
		},
		lg: {
			name: 'LG',
			slug: 'lg'
		},
		maxa: {
			name: 'Maxa',
			slug: 'maxa'
		},
		regulus: {
			name: 'Regulus',
			slug: 'regulus'
		},
		samsung: {
			name: 'Samsung',
			slug: 'samsung'
		},
		templari: {
			name: 'Templari',
			slug: 'templari'
		},
		vaillant: {
			name: 'Vaillant',
			slug: 'vaillant'
		},
		viessmann: {
			name: 'Viessmann',
			slug: 'viessmann'
		}
	},

	WOLTAIR_PL: {
		wltr: {
			name: 'WLTR',
			slug: 'wltr'
		},
		bosch: {
			name: 'Bosch',
			slug: 'bosch'
		},
		nibe: {
			name: 'Nibe',
			slug: 'nibe'
		},
		stiebel: {
			name: 'Stiebel',
			slug: 'stiebel'
		},
		'alpha-innotec': {
			name: 'Alpha Innotec',
			slug: 'alpha-innotec'
		},
		buderus: {
			name: 'Buderus',
			slug: 'buderus'
		},
		daikin: {
			name: 'Daikin',
			slug: 'daikin'
		},
		'de-dietrich': {
			name: 'De Dietrich',
			slug: 'de-dietrich'
		},
		grundig: {
			name: 'Grundig',
			slug: 'grundig'
		},
		'hermann-saunier-duval': {
			name: 'Hermann Saunier Duval',
			slug: 'hermann-saunier-duval'
		},
		kronoterm: {
			name: 'Kronoterm',
			slug: 'kronoterm'
		},
		lg: {
			name: 'LG',
			slug: 'lg'
		},
		maxa: {
			name: 'Maxa',
			slug: 'maxa'
		},
		regulus: {
			name: 'Regulus',
			slug: 'regulus'
		},
		samsung: {
			name: 'Samsung',
			slug: 'samsung'
		},
		templari: {
			name: 'Templari',
			slug: 'templari'
		},
		vaillant: {
			name: 'Vaillant',
			slug: 'vaillant'
		},
		viessmann: {
			name: 'Viessmann',
			slug: 'viessmann'
		}
	},

	WOLTAIR_DE: {
		wltr: {
			name: 'WLTR',
			slug: 'wltr'
		},
		daikin: {
			name: 'Daikin',
			slug: 'daikin'
		},
		lg: {
			name: 'LG',
			slug: 'lg'
		},
		samsung: {
			name: 'Samsung',
			slug: 'samsung'
		},
		vaillant: {
			name: 'Vaillant',
			slug: 'vaillant'
		},
		viessmann: {
			name: 'Viessmann',
			slug: 'viessmann'
		}
	},
	WOLTAIR_IT: {
		wltr: {
			name: 'WLTR',
			slug: 'wltr'
		},
		daikin: {
			name: 'Daikin',
			slug: 'daikin'
		},
		grundig: {
			name: 'Grundig',
			slug: 'grundig'
		},
		'hermann-saunier-duval': {
			name: 'Hermann Saunier Duval',
			slug: 'hermann-saunier-duval'
		},
		kronoterm: {
			name: 'Kronoterm',
			slug: 'kronoterm'
		},
		lg: {
			name: 'LG',
			slug: 'lg'
		},
		maxa: {
			name: 'Maxa',
			slug: 'maxa'
		},
		samsung: {
			name: 'Samsung',
			slug: 'samsung'
		},
		templari: {
			name: 'Templari',
			slug: 'templari'
		}
	}
}
export const HVAC_BRANDS = HVAC_BRANDS_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

//this is woltadvisor/marketplace const
export const hvacBrands = {
	alpha_innotec: {
		name: 'Alpha Innotec',
		countries: ['CZ', 'PL'],
		slug: 'alpha-innotec'
	},
	daikin: {
		name: 'Daikin',
		countries: ['CZ', 'DE', 'IT'],
		slug: 'daikin'
	},
	samsung: {
		name: 'Samsung',
		countries: ['CZ', 'DE', 'IT'],
		slug: 'samsung'
	},
	viessmann: {
		name: 'Viessmann',
		countries: ['CZ', 'DE'],
		slug: 'viessmann'
	},
	stiebel: {
		name: 'Stiebel',
		countries: ['CZ', 'DE'],
		slug: 'stiebel'
	},
	wltr: {
		name: 'WLTR',
		countries: ['CZ', 'DE', 'IT'],
		slug: 'wltr'
	},
	vaillant: {
		name: 'Vaillant',
		countries: ['CZ', 'DE', 'PL'],
		slug: 'vaillant'
	},
	bosch: {
		name: 'Bosch',
		countries: ['CZ'],
		slug: 'bosch'
	},
	buderus: {
		name: 'Buderus',
		countries: ['CZ', 'PL'],
		slug: 'buderus'
	},
	regulus: {
		name: 'Regulus',
		countries: ['CZ'],
		slug: 'regulus'
	},
	de_dietrich: {
		name: 'De Dietrich',
		countries: ['CZ'],
		slug: 'de-dietrich'
	},
	hsd: {
		name: 'Hermann Saunier Duval',
		countries: ['IT'],
		slug: 'hsd'
	},
	nibe: {
		name: 'Nibe',
		countries: ['CZ'],
		slug: 'nibe'
	},
	grundig: {
		name: 'Grundig',
		countries: ['CZ', 'IT'],
		slug: 'grundig'
	},
	kronoterm: {
		name: 'Kronoterm',
		countries: ['IT'],
		slug: 'kronoterm'
	},
	lg: {
		name: 'LG',
		countries: ['CZ', 'DE', 'IT', 'PL'],
		slug: 'lg'
	},
	maxa: {
		name: 'Maxa',
		countries: ['IT'],
		slug: 'maxa'
	},
	templari: {
		name: 'Templari',
		countries: ['IT'],
		slug: 'templari'
	},
	panasonic: {
		name: 'Panasonic',
		countries: ['PL'],
		slug: 'panasonic'
	},
	york: {
		name: 'York',
		countries: ['PL'],
		slug: 'york'
	}
}

export const NEWSLETTER_POPUP_DELAY = 15000

interface MarketplaceDefaults {
	HVAC: WoltAdvisorFromQueryRequest
	PV: WoltAdvisorFveRequest
}

export const MARKETPLACE_DEFAULT_VALUES_INIT: LocalesDefaults = {
	WOLTAIR_CZ: {
		HVAC: {
			houseHeatType: HouseHeatType.Electro,
			regionName: 'Středočeský kraj',
			airCondition: false,
			waterHeatingWithHouseHeatSource: true,
			organization: { id: `${ORGANIZATION['WOLTAIR_CZ'].id}` },
			peopleCount: 3,
			floorSquare: 150,
			isoLevel: IsoLevel.High
		},
		PV: {
			houseHeatType: HouseHeatType.Electro,
			region: 'Středočeský kraj',
			peopleCount: 3,
			annualElectricityConsumption: 8,
			isoLevel: IsoLevel.High
		}
	},
	WOLTAIR_DE: {
		HVAC: {
			houseHeatType: HouseHeatType.Electro,
			regionName: 'Středočeský kraj',
			airCondition: false,
			waterHeatingWithHouseHeatSource: true,
			organization: { id: `${ORGANIZATION['WOLTAIR_DE'].id}` },
			peopleCount: 3,
			floorSquare: 150,
			isoLevel: IsoLevel.High
		},
		PV: {
			houseHeatType: HouseHeatType.Electro,
			region: 'Středočeský kraj',
			peopleCount: 3,
			annualElectricityConsumption: 8,
			isoLevel: IsoLevel.High
		}
	},
	WOLTAIR_PL: {
		HVAC: {
			houseHeatType: HouseHeatType.Electro,
			regionName: 'Středočeský kraj',
			airCondition: false,
			waterHeatingWithHouseHeatSource: true,
			organization: { id: `${ORGANIZATION['WOLTAIR_PL'].id}` },
			peopleCount: 3,
			floorSquare: 150,
			isoLevel: IsoLevel.High
		},
		PV: {
			houseHeatType: HouseHeatType.Electro,
			region: 'Středočeský kraj',
			peopleCount: 3,
			annualElectricityConsumption: 8,
			isoLevel: IsoLevel.High
		}
	},
	WOLTAIR_IT: {
		HVAC: {
			houseHeatType: HouseHeatType.Gas,
			regionName: 'Veneto',
			airCondition: false,
			waterHeatingWithHouseHeatSource: true,
			organization: { id: `${ORGANIZATION['WOLTAIR_IT'].id}` },
			peopleCount: 2,
			floorSquare: 140,
			isoLevel: IsoLevel.Medium
		},
		PV: {
			houseHeatType: HouseHeatType.Gas,
			region: 'Veneto',
			peopleCount: 2,
			annualElectricityConsumption: 8,
			isoLevel: IsoLevel.Medium
		}
	}
}

export const MARKETPLACE_DEFAULT_VALUES =
	MARKETPLACE_DEFAULT_VALUES_INIT[PUBLIC_WOLTAIR_WEB_CODE as keyof LocalesDefaults]
